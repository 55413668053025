import React from "react";
import { Link, withRouter } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import { HashLink as AnchorLink } from "react-router-hash-link";
import Location from "./Map";
import Description from "./Description";
import Flights from "./Flights";
import Insurance from "./Insurance";
import Impressions from "./Impressions";
import Transfer from "./Transfer";
import RentalCar from "./RentalCar";
import Additional from "./Additional";
import OfferCard from "./OfferCard";
import Rating from "./Rating";
import ButtonBackBeforeForce from "./ButtonBackBeforeForce";
import Videos from "./Videos";
import Attributes from "./Attributes";
import Atmosfair from "./Atmosfair";
import * as helper from "../../helper";
import Gallery from "./Gallery";

/* eslint react/no-unescaped-entities: "off" */

/* eslint object-curly-newline: "off" */

class OfferDetailAccommodation extends React.PureComponent {
  componentDidMount() {
    const { match, onNavigate } = this.props;

    onNavigate(Number(match.params.id), Number(match.params.offerNumber), null);
  }

  navOnUpdate = (section, context = {}) => {
    const { match, onNavigate } = this.props;

    onNavigate(
      Number(match.params.id),
      Number(match.params.offerNumber),
      section || null,
      context || null
    );
  };

  changeOffer = (offerNumber) => {
    const { offers, history, match, matomoHandler } = this.props;

    const highestOrderNumber = offers.length;
    let nextOfferNumber = offerNumber;

    if (offerNumber <= 0) {
      nextOfferNumber = highestOrderNumber;
    }

    if (offerNumber > highestOrderNumber) {
      nextOfferNumber = 1;
    }

    const offer = offers.find((item) => item.number === nextOfferNumber);

    matomoHandler(offer.id, false);

    history.push(
      `/${match.params.sessionKey}/offer/${offer.id}/${offer.number}`
    );
  };

  onNavigateGallery = (index) => {
    const { onNavigateGallery } = this.props;

    this.navOnUpdate(null, {
      galleryImage: index,
    });

    onNavigateGallery(index);
  };

  onGalleryOpened = () => {
    const { galleryImage } = this.props;

    this.navOnUpdate(null, {
      galleryImage,
    });
  };

  onGalleryClosed = () => {
    this.navOnUpdate(null, {
      galleryImage: null,
    });
  };

  onGalleryClose = () => {
    const { onGalleryClose } = this.props;

    onGalleryClose();
  };

  render() {
    const {
      history,
      offers,
      match,
      matomoHandler,
      votings,
      vote,
      onBack,
      displayBackBeforeForceBtn,
      onBackBeforeForce,
      bookingLink,
      displayPrices,
      galleryImage,
      preview,
      googleMapsConfig,
      portalImage,
    } = this.props;

    if (!offers) {
      return null;
    }

    const offerNumber = match.params.offerNumber
      ? Number(match.params.offerNumber)
      : 1;

    const offer = offers.find(
      (offerItem) => offerItem.id === Number(match.params.id)
    );

    if (!offer) {
      history.push(`/${match.params.sessionKey}/${offerNumber}`);

      return null;
    }

    const backLink = `/${match.params.sessionKey}/${offerNumber}`;

    const rentalCars = offer.services.filter(
      (service) =>
        service.type === "RentalCar" && service.providerName !== "Atmosfair"
    );

    const atmosfairs = offer.services.filter(
      (service) =>
        service.type !== "RentalCar" && service.providerName === "Atmosfair"
    );

    const insurances = offer.services.filter(
      (service) => service.type === "Insurance"
    );

    let transfers = offer.services.filter(
      (service) => service.type === "Transfer"
    );

    transfers = helper.sortTransferServices(transfers);

    const additionals = offer.services.filter(
      (service) =>
        helper.serviceFeeGuids.includes(service.guid.toUpperCase()) &&
        service.isObligatory === false
    );

    const ownServices = helper.getCustomServices(offer);

    return (
      <div className="OfferDetail">
        <div className="row ml-0">
          <div className="col-3 pl-3">
            <OfferCard
              offer={offer}
              votingState={votings[offer.id]}
              vote={vote}
              hasImage={false}
              hasVotingButton
              hasLink={false}
              displayPrices={displayPrices}
              headerWithNav
              changeOffer={this.changeOffer}
              detail
            />
            {!offer.isExpired && (
              <a
                href={bookingLink}
                className="btn btn-booking btn-block mt-3"
                target={preview ? "_self" : "_blank"}
                rel="noopener noreferrer"
              >
                Jetzt buchen
              </a>
            )}
            <Link
              to={backLink}
              onClick={() => {
                onBack(backLink);
                matomoHandler(null, true);
              }}
              className="btn btn-secondary btn-block mt-3"
            >
              Zurück zur Übersicht
            </Link>
            {displayBackBeforeForceBtn && (
              <ButtonBackBeforeForce onBackBeforeForce={onBackBeforeForce} />
            )}
          </div>
          <div className="col-7">
            <div
              id="scroll-container"
              className="OfferDetail__scroll-container"
              style={{ maxHeight: "calc(100vh - 118px)", overflowY: "auto" }}
            >
              <div className="">
                <div className="card-body px-4">
                  {helper.hasAttributes(offer) && <Attributes offer={offer} />}
                  {helper.hasDescription(offer) && (
                    <Description
                      offer={offer}
                      onNavigateGallery={this.onNavigateGallery}
                    />
                  )}
                  {helper.hasFlights(offer) && <Flights offer={offer} />}
                  {helper.hasLocation(offer) && (
                    <Location
                      offer={offer}
                      googleMapsConfig={googleMapsConfig}
                    />
                  )}
                  {helper.hasImpressions(offer) && !helper.isCharter(offer) && (
                    <Impressions
                      images={offer.accommodation.images}
                      onNavigateGallery={this.onNavigateGallery}
                    />
                  )}
                  {helper.hasVideos(offer) && <Videos videos={offer.videos} />}
                  {helper.hasRating(offer) && (
                    <Rating
                      offerRatingProvider={
                        offer.accommodation.offerRatingProvider
                      }
                    />
                  )}
                  {helper.hasServices(offer) && (
                    <section id="services" className="OfferDetail__section">
                      <header className="OfferDetail__section-header">
                        <h1 className="OfferDetail__section-headline h1">
                          Zusatzangebote
                        </h1>
                      </header>
                      {additionals.length > 0 && (
                        <Additional
                          additionals={additionals}
                          portalImage={portalImage}
                        />
                      )}
                      {ownServices.length > 0 && (
                        <Additional
                          additionals={ownServices}
                          evaService={true}
                        />
                      )}
                      {transfers.length > 0 && (
                        <Transfer transfers={transfers} />
                      )}
                      {rentalCars.length > 0 && (
                        <RentalCar rentalCars={rentalCars} />
                      )}
                    </section>
                  )}
                  {helper.hasInsurance(offer) && (
                    <Insurance insurances={insurances} />
                  )}
                  {helper.hasAtmosfairs(offer) && (
                    <section id="atmosfairs" className="OfferDetail__section">
                      <header className="OfferDetail__section-header">
                        <h1 className="OfferDetail__section-headline h1">
                          CO2-Kompensieren
                        </h1>
                      </header>
                      <Atmosfair atmosfairs={atmosfairs} />
                    </section>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <Scrollspy
              className="Scrollspy"
              rootEl="#scroll-container"
              offset={-90}
              items={[
                "attributes",
                "desc",
                "flights",
                "location",
                "impressions",
                "videos",
                "rating",
                "services",
                "insurance",
                "atmosfairs",
              ]}
              currentClassName="Scrollspy__item--active"
              onUpdate={(activeSection) => this.navOnUpdate(activeSection.id)}
            >
              {helper.hasAttributes(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#attributes"
                    replace
                    className="btn btn-secondary"
                  >
                    Info zum Angebot
                  </AnchorLink>
                </li>
              )}
              {helper.hasDescription(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#desc"
                    replace
                    className="btn btn-secondary"
                  >
                    Beschreibung
                  </AnchorLink>
                </li>
              )}
              {helper.hasFlights(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#flights"
                    replace
                    className="btn btn-secondary"
                  >
                    Ihr Flug
                  </AnchorLink>
                </li>
              )}
              {helper.hasLocation(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#location"
                    replace
                    className="btn btn-secondary"
                  >
                    Lage
                  </AnchorLink>
                </li>
              )}
              {helper.hasImpressions(offer) && !helper.isCharter(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#impressions"
                    replace
                    className="btn btn-secondary"
                  >
                    Impressionen
                  </AnchorLink>
                </li>
              )}
              {helper.hasVideos(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#videos"
                    replace
                    className="btn btn-secondary"
                  >
                    {offer.videos.length > 1 ? "Videos" : "Video"}
                  </AnchorLink>
                </li>
              )}
              {helper.hasRating(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#rating"
                    replace
                    className="btn btn-secondary"
                  >
                    Bewertungen
                  </AnchorLink>
                </li>
              )}
              {helper.hasServices(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#services"
                    replace
                    className="btn btn-secondary"
                  >
                    Zusatzangebote
                  </AnchorLink>
                </li>
              )}
              {helper.hasInsurance(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#insurance"
                    replace
                    className="btn btn-secondary"
                  >
                    Reiseschutz
                  </AnchorLink>
                </li>
              )}
              {helper.hasAtmosfairs(offer) && (
                <li className="Scrollspy__item">
                  <AnchorLink
                    smooth
                    to="#atmosfairs"
                    replace
                    className="btn btn-secondary"
                  >
                    CO2-Kompensieren
                  </AnchorLink>
                </li>
              )}
            </Scrollspy>
          </div>
        </div>
        {galleryImage !== null && (
          <Gallery
            index={galleryImage}
            images={offer.accommodation.images}
            onNavigateGallery={this.onNavigateGallery}
            onGalleryClose={this.onGalleryClose}
            onGalleryOpened={this.onGalleryOpened}
            onGalleryClosed={this.onGalleryClosed}
          />
        )}
      </div>
    );
  }
}

export default withRouter(OfferDetailAccommodation);
