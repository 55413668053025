import React from 'react';

export default class AgentImage extends React.Component {
  state = {
    image: '/agent_fallback.svg',
  };

  componentDidMount() {
    const { path } = this.props;

    const image = new Image();
    image.onload = () => {
      this.setState({
        image: path,
      });
    };

    image.src = path;
  }

  render() {
    const { image } = this.state;

    return (
      <img
        className="AgentImage__consultant-img"
        src={image}
        alt="agent"
      />
    );
  }
}
