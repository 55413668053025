import React from 'react';

const DEFAULT_FALLBACK_IMAGE = 'https://cdn.smartberatung.com/fallback/NoImageAvailable.png';

class ImageFallback extends React.Component {
  state = {
    error: false,
  };

  componentDidMount() {
    const { image } = this.props;

    if (!image) {
      this.setState({
        error: true,
      });

      return;
    }

    const el = new Image();
    el.onerror = () => {
      this.setState({
        error: true,
      });
    };

    el.onload = () => {
      if (el.width <= 1) {
        this.setState({
          error: true,
        });
      }
    };

    el.src = image;
  }

  render() {
    const { children, image, fallback = DEFAULT_FALLBACK_IMAGE } = this.props;
    const { error } = this.state;

    return children(error ? fallback : image);
  }
}

export default ImageFallback;
