import React from "react";

class DropdownAction extends React.PureComponent {
  state = {
    open: false,
  };

  buttonRef = React.createRef();

  componentDidMount() {
    document.addEventListener("click", this.onBackgroundClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onBackgroundClick);
  }

  onBackgroundClick = (event) => {
    if (this.buttonRef?.current.contains(event.target)) {
      return;
    }

    this.setState({
      open: false,
    });
  };

  onToggle = () => {
    const { open } = this.state;

    this.setState({
      open: !open,
    });
  };

  render() {
    const { children, actions, guests } = this.props;
    const { open } = this.state;

    return (
      <div className="ClientWidget__advisory-number-smart-key dropdown-container">
        <div
          className="dropdown-button"
          onClick={this.onToggle}
          ref={this.buttonRef}
        >
          {children}
          {open && (
            <div className="dropdown-action-container">
              {Object.entries(actions).map(([label, onClick]) =>
                this.renderActions(label, onClick)
              )}
            </div>
          )}
          <br />
          <div>
            Anzahl Teilnehmer:{" "}
            <span className="font-weight-bold">{guests}</span>
          </div>
        </div>
      </div>
    );
  }

  renderActions(label, onClick) {
    return (
      <div key={label} className="dropdown-action" onClick={onClick}>
        {label}
      </div>
    );
  }
}

export default DropdownAction;
