import React from "react";

function renderPrittyDateTime(timestamp) {
  const date = new Date(timestamp);

  const weekdayShortWritten = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

  return `${weekdayShortWritten[date.getDay()]} ${date.getDate()}.${
    date.getMonth() + 1
  } 
  um ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")} Uhr`;
}

const SessionListElement = ({ session, onClick }) => {
  return (
    <button
      className="SessionHeaderInformation d-flex align-items-center"
      onClick={() => onClick(session)}
      type="button"
    >
      {session.isRunning ? (
        <BusyElement session={session} />
      ) : (
        <FreeElement session={session} />
      )}
    </button>
  );
};

const FreeElement = ({ session }) => (
  <React.Fragment>
    <h4 className="mb-0 mr-2">
      <span className="badge py-2 badge-success">{session.name}</span>
    </h4>
    <div>
      <div>
        <span className="font-weight-bold">frei</span>
      </div>
    </div>
  </React.Fragment>
);

const BusyElement = ({ session }) => (
  <React.Fragment>
    <h4 className="mb-0 mr-2">
      <span className="badge py-5 badge-danger">{session.name}</span>
    </h4>
    <div>
      <div>
        smart-Key: <span className="font-weight-bold">{session.id}</span>
      </div>
      <div>Beratung: {session.advisoryId}</div>
      <hr />
      <div>{session.advisor}</div>
      <div>{renderPrittyDateTime(session.lastModified)}</div>
    </div>
  </React.Fragment>
);

export default SessionListElement;
