import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import api from "../../api";
import env from "../../env";
import WelcomeScreensWrapper from "./WelcomeScreensWrapper";
import { QRCodeCanvas } from "qrcode.react";

const REACT_APP_SMART_APP_URL = env("REACT_APP_SMART_APP_URL");
const REACT_APP_SIGNALR_HUB_URL = env("REACT_APP_SIGNALR_HUB_URL");

const useSignalR = (overviewId, onReload) => {
  const [signalr, setSignalR] = useState();

  useEffect(() => {
    (async () => {
      const signalrConn = new signalR.HubConnectionBuilder()
        .withUrl(REACT_APP_SIGNALR_HUB_URL)
        .build();

      signalrConn.onclose(async () => {
        await signalrConn.start();

        try {
          await signalrConn.invoke(
            "RegisterConnectionAsync",
            JSON.stringify({
              Role: "Guest",
              AgencyOverviewId: overviewId,
            })
          );
        } catch (err) {
          console.log(err);
        }
      });

      signalrConn.on("ReceiveError", (message) => {
        const error = JSON.parse(message);
        console.log(error);
      });

      signalrConn.on("ReceiveStartSession", (_) => onReload());

      signalrConn.on("ReceiveStopSession", (_) => onReload());

      await signalrConn.start();

      await signalrConn.invoke(
        "RegisterConnectionAsync",
        JSON.stringify({
          Role: "Guest",
          AgencyOverviewId: overviewId,
        })
      );

      setSignalR(signalrConn);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  return { signalr };
};

const useAgencyOverview = (agencyOverviewId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [refetchReq, setRefetchReq] = useState(false);
  const [overviewData, setOverviewData] = useState();

  useEffect(() => {
    (async () => {
      const data = await api.getOverviewData(agencyOverviewId).catch(() => {
        setIsLoading(false);
      });

      setOverviewData(data);
      setRefetchReq(false);
      setIsLoading(false);
    })();
  }, [agencyOverviewId, refetchReq]);

  return { isLoading, overviewData, fetchData: setRefetchReq };
};

const useInitOverview = (overviewId, onReload) => {
  const { isLoading, overviewData, fetchData } = useAgencyOverview(overviewId);
  const { signalr } = useSignalR(overviewId, onReload);

  return { isLoading, overviewData, fetchData, signalr };
};

const AgencyOverview = ({ match }) => {
  const search = useLocation().search;
  const preview = new URLSearchParams(search)?.get("preview") ?? false;

  const reload = () => fetchData(true);

  const { agencyOverviewId } = match.params;

  const { isLoading, overviewData, fetchData } = useInitOverview(
    agencyOverviewId,
    reload
  );

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    let manifestDom = document.querySelector("#manifest");

    if (!manifestDom) {
      document.head.insertAdjacentHTML(
        "beforeend",
        '<link id="manifest" rel="manifest" />'
      );

      manifestDom = document.querySelector("#manifest");

      if (!manifestDom) {
        return;
      }
    }

    const manifest = {
      "short_name": "Smartberatung",
      "name": "Smartberatung",
      "icons": [
        {
          "src": "https://smartberatung.com/favicon.ico",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        }
      ],
      "start_url": `/overview/${agencyOverviewId}`,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    };

    const stringManifest = JSON.stringify(manifest);
    const blobManifest = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blobManifest);

    manifestDom.setAttribute("href", manifestURL);
  }, [agencyOverviewId]);

  if (isLoading) {
    return <WelcomeScreensWrapper>{"Bitte warten ..."}</WelcomeScreensWrapper>;
  }

  if (!overviewData) {
    return <WelcomeScreensWrapper>{"Nicht gefunden!"}</WelcomeScreensWrapper>;
  }

  if (redirectTo) {
    return (
      <Redirect
        to={{
          pathname: `/${redirectTo}`,
          state: { agencyOverviewId },
        }}
      />
    );
  }

  return (
    <>
      <WelcomeScreensWrapper>
        <div className="AgencyOverview">
          {overviewData.sessions.map((session) => {
            return (
              <div
                key={session.pos}
                className={`AgencyOverview__session${
                  session.isRunning ? " AgencyOverview__isRunning" : ""
                }`}
                onClick={() => {
                  if (!session.isRunning) return;
                  setRedirectTo(session.id);
                }}
              >
                <div className="AgencyOverview__title">{session.name}</div>
                <div>
                  {session.isRunning ? session.advisor : "Nicht verfügbar"}
                </div>
              </div>
            );
          })}
        </div>
      </WelcomeScreensWrapper>
      {preview && (
        <div
          style={{
            position: "absolute",
            left: "1rem",
            bottom: "3rem",
            backgroundColor: "#fff",
            width: "84px",
            height: "84px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCodeCanvas
            value={`${REACT_APP_SMART_APP_URL}/overview/${agencyOverviewId}`}
            size={80}
          />
        </div>
      )}
    </>
  );
};

export default AgencyOverview;
