import React from 'react';
import { ReactComponent as IconNegative } from '../../assets/img/dislike.svg';
import { ReactComponent as IconPositive } from '../../assets/img/like.svg';

/* eslint react/no-did-update-set-state: "off" */

class VotingShow extends React.PureComponent {
  state = {
    animate: false,
  };

  componentDidUpdate() {
    this.setState({
      animate: true,
    });
  }

  render() {
    const { votingState } = this.props;
    const { animate } = this.state;

    if (votingState === 'thumbs-up') {
      return (
        <div className={`VotingShow VotingShow--positive${animate ? ' VotingShow--animate' : ''}`}>
          <IconPositive />
        </div>
      );
    }

    if (votingState === 'thumbs-down') {
      return (
        <div className={`VotingShow VotingShow--negative${animate ? ' VotingShow--animate' : ''}`}>
          <IconNegative />
        </div>
      );
    }

    return null;
  }
}

export default VotingShow;
