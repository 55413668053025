import React, { useState } from "react";
import moment from "moment";
import { GoogleMap } from "../../components/smartApp/Map";
import GoogleCookieOverlay from "../../components/smartApp/GoogleCookieOverlay";

export const MAP_TYPE = {
  PickUp: "pickup",
  DropOff: "dropoff",
  Both: "identical",
};

function LocationRentalService({
  type,
  location,
  dropOffDateTime,
  title,
  anchorid,
  googleMapsConfig,
}) {
  const [enabled, setEnabled] = useState(false);

  return (
    <section id={anchorid} className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h2 className="OfferDetail__section-headline h1">{title}</h2>
      </header>
      <table width="100%">
        <tbody>
          <tr>
            <td width="40%"></td>
          </tr>
          {location.handOverType && (
            <tr>
              <td>
                <b>Stationsart:</b>
              </td>
              <td>{location.handOverType}</td>
            </tr>
          )}
          {location.name && (
            <tr>
              <td>
                <b>Stationsname:</b>
              </td>
              <td>{location.name}</td>
            </tr>
          )}
          {location.street && (
            <tr>
              <td>
                <b>Strasse/Standort:</b>
              </td>
              <td>{location.street}</td>
            </tr>
          )}
          {location.zipPostal && (
            <tr>
              <td>
                <b>PLZ/Stadt:</b>
              </td>
              <td>{location.zipPostal + " " + location.city}</td>
            </tr>
          )}
          {location.region && (
            <tr>
              <td>
                <b>Land/Region:</b>
              </td>
              <td>{location.region}</td>
            </tr>
          )}
          {location.country && (
            <tr>
              <td>
                <b>Staat:</b>
              </td>
              <td>{location.country}</td>
            </tr>
          )}
          {location.openingHours && location.openingHours.length > 0 && (
            <React.Fragment>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <b>Öffnungszeiten:</b>
                </td>
              </tr>

              {location.openingHours.slice(0).map((openingHour, index) => (
                <tr key={index}>
                  <td>{openingHour.daysDisplayText}</td>
                  <td>{openingHour.hoursDisplayText}</td>
                </tr>
              ))}
            </React.Fragment>
          )}
          {location.time && location.time.length > 0 && type && (
            <React.Fragment>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {(type === MAP_TYPE["PickUp"] ||
                type === MAP_TYPE["DropOff"]) && (
                <React.Fragment>
                  <tr>
                    <td>
                      {type === MAP_TYPE["PickUp"] && <b>Abholung am:</b>}
                      {type === MAP_TYPE["DropOff"] && <b>Rückgabe am:</b>}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {moment
                        .utc(location.time)
                        .locale("de")
                        .format("dddd, DD.MM.YYYY")}
                    </td>
                    <td>
                      {moment.utc(location.time).locale("de").format("HH:mm")}{" "}
                      Uhr
                    </td>
                  </tr>
                </React.Fragment>
              )}
              {type === MAP_TYPE["Both"] && (
                <React.Fragment>
                  <tr>
                    <td>
                      <b>Abholung am:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {moment
                        .utc(location.time)
                        .locale("de")
                        .format("dddd, DD.MM.YYYY")}
                    </td>
                    <td>
                      {moment.utc(location.time).locale("de").format("HH:mm")}{" "}
                      Uhr
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Rückgabe am:</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {moment
                        .utc(dropOffDateTime)
                        .locale("de")
                        .format("dddd, DD.MM.YYYY")}
                    </td>
                    <td>
                      {moment.utc(dropOffDateTime).locale("de").format("HH:mm")}{" "}
                      Uhr
                    </td>
                  </tr>
                </React.Fragment>
              )}
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <small>
                    (alle Uhrzeiten sind in der Lokalzeit des Reiseortes
                    ausgewiesen){" "}
                  </small>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
      {enabled ? (
        <GoogleMap
          className="my-3"
          lat={Number(location.latitude)}
          lng={Number(location.longitude)}
          googleMapsConfig={{
            fallbackGoogleMapsApiKey: googleMapsConfig.fallbackGoogleMapsApiKey,
            configuredGoogleMapsApiKey:
              googleMapsConfig.configuredGoogleMapsApiKey,
            googleMapsCustomParameters: "maptype=roadmap&zoom=16&language=de",
          }}
        />
      ) : (
        <GoogleCookieOverlay setEnabled={setEnabled} />
      )}
    </section>
  );
}

export default LocationRentalService;
