import React from "react";
import * as signalR from "@microsoft/signalr";
import DisplayError from "./DisplayError";
import env from "../../env";
import themeApp from "../../themeApp";
import SessionHeaderInformation from "./SessionHeaderInformation";
import SessionHeaderButtons from "./SessionHeaderButtons";
import OfferWrapper from "./OfferWrapper";
import Video from "../common/Video";
import Modal from "./Modal";
import api from "../../api";
import DropdownAction from "./DropdownAction";
import debounce from "lodash.debounce";

import { sendMatomoTrackingData } from "../common/MatomoTracking";

function copyToClipboard(text) {
  const input = document.createElement("input");
  input.value = text;

  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);

  input.remove();
}

class SessionControl extends React.Component {
  state = {
    advisoryId: null,
    data: null,
    video: false,
    error: null,
    displayModal: false,
    navigation: {},
    visit: [],
    votes: {},
    showPrices: false,
    showFullscreenVideo: false,
    audience: {
      guests: 0,
      hosts: 0,
    },
    lastTrackingOP: null,
  };

  async componentDidMount() {
    const { token, onSessionTerminate } = this.props;
    const REACT_APP_SIGNALR_HUB_URL = env("REACT_APP_SIGNALR_HUB_URL");

    this.signal = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_SIGNALR_HUB_URL)
      .build();

    this.signal.on("Navigate", (message) => {
      const { viewed } = this.state;
      const nav = JSON.parse(message);

      this.setState({
        navigation: nav,
      });

      if (nav.offerId && !viewed.includes(nav.offerId)) {
        this.setState({
          viewed: [...viewed, nav.offerId],
        });
      }
    });

    this.signal.on("ReceiveStartSession", async (message) => {
      const parsedMessage = JSON.parse(message);

      let data;

      try {
        data = await api.getAdvisory(parsedMessage.advisoryId, token);
      } catch (e) {
        if (e.code === 400 || e.code === 404) {
          this.setState({
            error: e?.data?.message,
          });
        } else {
          this.setState({
            error: undefined,
          });
        }

        return;
      }

      themeApp(data.portal);

      const votes = {};

      if (parsedMessage.votes) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of parsedMessage.votes) {
          votes[item.offerId] = item.vote;
        }
      }

      this.setState({
        advisoryId: parsedMessage.advisoryId,
        navigation: parsedMessage.navigate || {},
        visit: parsedMessage.visited.offerIds,
        showPrices: parsedMessage.settings.showPrices,
        audience: parsedMessage.audience,
        votes,
        data,
      });

      this.handleMatomoTracking(null, "SessionStart");
    });

    this.signal.on("ReceiveAdvisoryUpdated", async () => {
      const { advisoryId } = this.state;

      if (!advisoryId) {
        return;
      }

      let newData;

      try {
        newData = await api.getAdvisory(advisoryId, token);
      } catch (e) {
        if (e.code === 400 || e.code === 404) {
          this.setState({
            error: e?.data?.message,
          });
        } else {
          this.setState({
            error: undefined,
          });
        }

        return;
      }

      themeApp(newData.portal);

      this.setState({ data: newData });
    });

    this.signal.on("Vote", (message) => {
      const { votes } = this.state;
      const parsedData = JSON.parse(message);

      this.setState({
        votes: {
          ...votes,
          [parsedData.offerId]: parsedData.vote,
        },
      });
    });

    this.signal.on("ReceiveVisited", (message) => {
      const parsedData = JSON.parse(message);

      this.setState({
        visit: parsedData.offerIds,
      });
    });

    this.signal.on("ReceiveStopSession", () => {
      onSessionTerminate();
    });

    this.signal.on("ReceiveCallCommand", (message) => {
      const parsedData = JSON.parse(message);

      if (parsedData.command === "StopCall") {
        this.setState({
          video: false,
        });
      }
    });

    const updateAudience = (message) => {
      const parsedData = JSON.parse(message);

      this.setState({
        audience: parsedData,
      });
    };

    this.signal.on("ReceiveHostConnected", updateAudience);
    this.signal.on("ReceiveGuestConnected", updateAudience);
    this.signal.on("ReceiveHostDisconnected", updateAudience);
    this.signal.on("ReceiveGuestDisconnected", updateAudience);

    this.signal.onclose(this.connect);
    await this.connect();
  }

  handleMatomoTracking = (offerId, operation = null) => {
    if (!this.state.advisoryId) return;

    const lastTrackingOP = this.state.lastTrackingOP;

    let currentTrackingOP;

    if (offerId)
      currentTrackingOP =
        this.props.selectedSession.id + (offerId ? `/offer/${offerId}` : ``);
    else
      currentTrackingOP = this.props.selectedSession.id + this.state.advisoryId;

    if (operation || !lastTrackingOP || lastTrackingOP !== currentTrackingOP) {
      sendMatomoTrackingData(
        "Expedient",
        operation ? operation : offerId ? "Detail" : "Overview",
        offerId
          ? `${this.state.advisoryId}/${offerId}`
          : `${this.state.advisoryId}`
      );
      this.setState({
        lastTrackingOP: currentTrackingOP,
      });
    }
  };

  componentWillUnmount() {
    if (this.signal) {
      this.signal.stop();
      this.signal = null;
    }
  }

  connect = async () => {
    if (!this.signal) {
      return;
    }

    try {
      await this.signal.start();
    } catch (err) {
      console.log(err);
      setTimeout(this.connect, 5000);
      return;
    }

    const { selectedSession } = this.props;

    try {
      await this.signal.invoke(
        "RegisterConnectionAsync",
        JSON.stringify({
          Role: "Host",
          SessionId: selectedSession.id,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  onOfferClickedDebounced = debounce((offerId, offerNumber) => {
    this.handleMatomoTracking(offerId);

    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerId,
        offerNumber,
        navigation: null,
        trackNav: true,
      })
    );
  }, 250);

  onNavClicked = (navigation, offerId, offerNumber) => {
    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerId,
        offerNumber,
        navigation,
      })
    );
  };

  onSliderChangeClicked = (
    navigation,
    offerId,
    offerNumber,
    trackNav = false
  ) => {
    this.handleMatomoTracking(offerId);

    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerId,
        offerNumber,
        navigation,
        trackNav,
      })
    );
  };

  onBackToOverviewClicked = () => {
    const { data, navigation } = this.state;

    const offerCount = (data && data.offers.length) || 0;
    const offerNumber = Math.min(
      (navigation && navigation.offerNumber) || 2,
      offerCount
    );

    this.handleMatomoTracking(null);

    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerNumber,
        offerId: null,
        navigation: null,
        trackNav: true,
      })
    );
  };

  onTerminateSessionClicked = async () => {
    const { selectedSession, token, onSessionTerminate } = this.props;

    await api.stopSession(selectedSession.id, token);

    console.log("session terminated for " + this.props);
    this.handleMatomoTracking(null, "SessionTerminate");

    onSessionTerminate();
  };

  sendSMInvitation = async () => {
    const { selectedSession, token } = this.props;

    await api.postSBInvitation(selectedSession.id, token);
  };

  onVideo = () => {
    const { video } = this.state;

    this.handleMatomoTracking(null, `VideoCall${!video ? "Start" : "Stop"}`);

    if (video) {
      this.setState({
        video: false,
      });

      this.signal.invoke(
        "SendCallCommandAsync",
        JSON.stringify({
          command: "StopCall",
          data: "",
        })
      );
    } else {
      this.setState({
        video: true,
      });

      this.signal.invoke(
        "SendCallCommandAsync",
        JSON.stringify({
          command: "StartCall",
          data: "",
        })
      );
    }

    this.toggleFullscreen(false);
  };

  onCloseGallery = () => {
    const { navigation } = this.state;

    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerNumber: navigation.offerNumber,
        offerId: navigation.offerId,
        navigation: null,
        context: {
          galleryImage: null,
        },
      })
    );
  };

  onNavigateGallery = (index) => {
    const { navigation } = this.state;

    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerNumber: navigation.offerNumber,
        offerId: navigation.offerId,
        navigation: navigation.navigation,
        context: {
          galleryImage: index,
        },
      })
    );
  };

  togglePrices = () => {
    const { token, selectedSession } = this.props;
    const { showPrices } = this.state;

    this.handleMatomoTracking(
      null,
      `TogglePrices${!showPrices ? "On" : "Off"}`
    );

    api.patchSession(
      selectedSession.id,
      {
        showPrices: !showPrices,
      },
      token
    );

    this.setState({
      showPrices: !showPrices,
    });
  };

  toggleFullscreen = (fullscreen) => {
    const { token, selectedSession } = this.props;

    api.patchSession(
      selectedSession.id,
      {
        showFullscreenVideo: fullscreen,
      },
      token
    );

    this.setState({
      showFullscreenVideo: fullscreen,
    });
  };

  openModal = () => {
    this.setState({
      displayModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      displayModal: false,
    });
  };

  onSessionHeaderButton = (operation) => {
    this.handleMatomoTracking(null, operation);
  };

  render() {
    const {
      advisoryId,
      visit,
      votes,
      error,
      navigation,
      data,
      video,
      displayModal,
      showFullscreenVideo,
      showPrices,
      audience,
    } = this.state;

    const { selectedSession, onSessionTerminate, paxVersion } = this.props;

    if (error !== null) {
      return <DisplayError>{error}</DisplayError>;
    }

    const REACT_APP_SMART_APP_URL = env("REACT_APP_SMART_APP_URL");

    return (
      <main className="ClientWidget">
        {displayModal && (
          <Modal closeModal={this.closeModal}>
            <p>
              Wollen Sie die smart-Beratung beenden oder zurück zur
              Übersichtsseite?
            </p>
            <button
              className="ClientWidget__modalBtn"
              type="button"
              onClick={this.onTerminateSessionClicked}
            >
              smart-Beratung beenden
            </button>
            <button
              className="ClientWidget__modalBtn"
              type="button"
              onClick={onSessionTerminate}
            >
              Zurück zur Übersichtsseite
            </button>
          </Modal>
        )}
        <DropdownAction
          actions={{
            "smart-Key kopieren": () => copyToClipboard(selectedSession.id),
            "Beratungslink kopieren": () =>
              copyToClipboard(
                `${REACT_APP_SMART_APP_URL}/${selectedSession.id}`
              ),
            "zur smart-Beratung einladen": () => this.sendSMInvitation(),
          }}
          guests={audience.guests}
        >
          Beratung:{" "}
          <span className="font-weight-bold">{advisoryId || "lädt..."}</span> |
          &nbsp;smart-Key:{" "}
          <span className="font-weight-bold">{selectedSession.id}</span>
        </DropdownAction>

        <header className="ClientWidget__header">
          <SessionHeaderInformation
            session={selectedSession}
            onClick={onSessionTerminate}
          />
          <SessionHeaderButtons
            selectedSessionKey={selectedSession.id}
            sessionTerminate={this.onTerminateSessionClicked}
            onVideo={this.onVideo}
            inCall={video}
            togglePrices={this.togglePrices}
            displayPrices={showPrices}
            openModal={this.openModal}
            paxVersion={paxVersion}
            matomoFunc={this.onSessionHeaderButton}
          />
        </header>
        {video && (
          <Video signal={this.signal} initiator onClose={this.onVideo} />
        )}
        {video && (
          <SessionVideoControls
            showFullscreenVideo={showFullscreenVideo}
            onToggle={this.toggleFullscreen}
          />
        )}
        <OfferWrapper
          viewed={visit}
          votes={votes}
          navigation={navigation}
          data={data}
          onOfferClicked={this.onOfferClickedDebounced}
          onNavClicked={this.onNavClicked}
          onBackToOverviewClicked={this.onBackToOverviewClicked}
          onSliderChangeClicked={this.onSliderChangeClicked}
          onNavigateGallery={this.onNavigateGallery}
          onCloseGallery={this.onCloseGallery}
          advisoryId={this.state.advisoryId}
        />
      </main>
    );
  }
}

class SessionVideoControls extends React.PureComponent {
  render() {
    const { showFullscreenVideo, onToggle } = this.props;

    return (
      <div className="SessionVideoControlls">
        <div className="Title">Video Vollbild</div>
        <div className="OnOffSwitch">
          <input
            id="sessionVideoControll-Fullscreen"
            type="checkbox"
            className="Checkbox"
            checked={showFullscreenVideo}
            onChange={(e) => onToggle(e.target.checked)}
          />
          <label className="Label" htmlFor="sessionVideoControll-Fullscreen">
            <span className="Inner"></span>
            <span className="Switch"></span>
          </label>
        </div>
      </div>
    );
  }
}

export default SessionControl;
