import React from 'react';

function Tag({ children }) {
  return (
    <div className="Tag">
      <span className="Tag__inner">
        {children}
      </span>
    </div>
  );
}

export default Tag;
