import React from 'react';
import { ReactComponent as Close } from '../../assets/img/times-light.svg';

function Modal({ closeModal, children, alternative }) {
  return (
    <div className={alternative ? `Modal Modal__alternative` : 'Modal'}>
      <div className="Modal__inner">
        {closeModal && (
          <button
            type="button"
            className="CircleButton Modal__close-btn"
            onClick={closeModal}
            title="Modal schließen"
          >
            <Close height={18} width={18}/>
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

export default Modal;
