// category: Expedient or Customer
// operation: detail/overview
// arg: "YYWC-9257 YYWC-9257/4711" /error/noerror

export const sendMatomoTrackingData = (category, operation, arg) => {
  if (arg && localStorage.getItem("preview") === null) {
    const currentHost = document.location.host;

    let siteId = -1;

    // "setSiteId", "5" -> www.smartberatung.com
    // "setSiteId", "11" -> ex.smartberatung.com
    // "setSiteId", "12" -> test-ex.smartberatung.com
    // "setSiteId", "13" -> test-www.smartberatung.com

    if (currentHost === "www.smartberatung.com") siteId = 5;
    else if (currentHost === "ex.smartberatung.com") siteId = 11;
    else if (currentHost === "test-www.smartberatung.com") siteId = 13;
    else if (currentHost === "test-ex.smartberatung.com") siteId = 12;
    else if (category === "Customer") siteId = 12;
    else siteId = 5;

    console.log(
      "MatomoTrack " + category + " " + operation + " " + arg + " " + siteId
    );

    var _paq = window._paq || [];

    _paq.push(["trackPageView", document.URL]);
    _paq.push(["enableLinkTracking"]);

    const existingScriptTag = document.getElementById("matomo-script");

    if (existingScriptTag) existingScriptTag.remove();

    (function () {
      var u = "https://stats.smartberatung.com/";

      _paq.push(["trackEvent", category, operation, arg]);
      _paq.push(["setTrackerUrl", u + "matomo.php"]);
      _paq.push(["setSiteId", siteId]);
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.type = "text/javascript";
      g.async = true;
      g.src = u + "matomo.js";
      g.id = "matomo-script";
      s.parentNode.insertBefore(g, s);
    })();
  }
};
