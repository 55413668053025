import React from 'react';
import * as helper from '../../helper';

function Atmosfair({ atmosfairs }) {
  return (
    <div className="OfferDetail__sub-section">
      {atmosfairs.map(atmosfair => (
        <div key={atmosfair.id}>
          <img className="d-block mb-3 w-25" src={atmosfair.image} alt="" />
          <h3 className="h4">{atmosfair.name}</h3>
          <div className="row">
            <div className="col-6">
              <div dangerouslySetInnerHTML={{ __html: atmosfair.description }} />
            </div>
            <div className="col-6">
              <h5 className="mb-0">{helper.convertISOCurrencyToSymbol(atmosfair.price)}</h5>
              <p>Gesamtpreis {atmosfair.duration}</p>
              <h6>Ihr Anbieter:</h6>
              <img className="w-25 mb-3" src={atmosfair.providerImage} alt={atmosfair.providerName} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Atmosfair;
