import React from 'react';

function ButtonBackBeforeForce({ onBackBeforeForce }) {
  return (
    <button
      type="button"
      className="btn btn-secondary btn-block mt-3"
      onClick={onBackBeforeForce}
    >
      Zurück zum letzten Angebot
    </button>
  );
}

export default ButtonBackBeforeForce;
