import * as THEME from "./themeDummyValues";

function themeApp(portal) {
  let colors;

  if (!portal.colors) {
    colors = [];
    colors.portalColor = THEME.COLOR;
    colors.portalColorText = THEME.COLOR_TEXT;
    colors.buttons = THEME.BUTTONS;
    colors.buttonsText = THEME.BUTTONS_TEXT;
    colors.eyeCatcher = THEME.EYECATCHER;
    colors.eyeCatcherText = THEME.EYECATCHER_TEXT;
    colors.background = THEME.BACKGROUND;
    colors.backgroundText = THEME.BACKGROUND_TEXT;
  } else {
    colors = portal.colors;
  }

  let theme = document.getElementById("custom-style");

  if (!theme) {
    theme = document.createElement("style");
    theme.id = "custom-style";
    document.head.appendChild(theme);
  }

  theme.innerHTML = `
        a,
        a:hover,
        .text-theme,
        .btn-theme:hover,
        .Loading {
            color: ${colors.portalColor || THEME.COLOR};
        }

        .OfferDetail__section-header,
        .ClientOfferList__btn--active,
        .ClientNavList__btn--active,
        .InfoBar__consultant-img {
            border-color: #343a40;
        }

        .OfferDetail__section-headline:after,
        .CallButton,
        .CallCloseButton,
        .VotingShow--positive {
            background-color: ${colors.portalColor || THEME.COLOR};
        }

        .OfferDetail__attribute {
            background-color: ${colors.eyeCatcher || THEME.EYECATCHER};
            color: ${colors.eyeCatcherText || THEME.EYECATCHER_TEXT};
            padding: 8px 15px;
            border-radius: 5px;
            margin-right: 10px;
            font-weight: bold;
            margin-top: 10px;
        }

        .Tag__inner {
            background-color: ${colors.eyeCatcher || THEME.EYECATCHER};
            color: ${colors.eyeCatcherText || THEME.EYECATCHER_TEXT};
        }

        .btn-primary,
        .slick-dots li.slick-active button
        .btn-theme,
        .InfoBar__first-line {
            border-color: ${colors.portalColor || THEME.COLOR};
            background-color: ${colors.portalColor || THEME.COLOR};
            color: ${colors.portalColorText || THEME.COLOR_TEXT};
        }

        .btn-booking {
            color: ${colors.buttonsText || THEME.BUTTONS_TEXT};
            background-color: ${colors.buttons || THEME.BUTTONS};
        }

        .bg-custom {
            background-color: ${colors.background || THEME.BACKGROUND};
        }
    `;

  let portalTheme = document.getElementById("portal-custom-style");

  if (portal.customCssActive && portal.customCss) {
    if (!portalTheme) {
      portalTheme = document.createElement("link");
      portalTheme.rel = "stylesheet";
      portalTheme.id = "portal-custom-style";
    }

    portalTheme.href = portal.customCss;
    document.head.appendChild(portalTheme);
  } else if (portalTheme) {
    portalTheme.remove();
  }
}

export default themeApp;
