import React from 'react';
import VotingShow from '../common/VotingShow';
import ViewedShow from '../common/ViewedShow';
import * as helper from '../../helper';

function getLiClassName(offerNumber, currentSlide, offersLength) {
  if (offersLength === 1) {
    return 'ClientOfferList__item ClientOfferList__item--in-view-single';
  }

  if (
    (offersLength >= 3 && offerNumber === currentSlide) || (offersLength === 2 && offerNumber === 1)) {
    return 'ClientOfferList__item ClientOfferList__item--in-view';
  }

  if (
    (offerNumber === (currentSlide + 1))
    || (offerNumber === 1 && currentSlide === offersLength)
    || (offersLength === 2 && offerNumber === 2)
  ) {
    return 'ClientOfferList__item ClientOfferList__item--in-view-right';
  }

  if (offerNumber === (currentSlide - 1) || (offerNumber === offersLength && currentSlide === 1)) {
    return 'ClientOfferList__item ClientOfferList__item--in-view-left';
  }

  return 'ClientOfferList__item';
}

const Item = ({
  offer, votings = [], activeOfferId, onOfferClicked, currentSlide, offersLength, viewed,
}) => {
  let btnClassNames = 'ClientOfferList__btn';

  if (activeOfferId !== null && offer.id === activeOfferId) {
    btnClassNames = 'ClientOfferList__btn ClientOfferList__btn--active';
  }

  const liClassNames = getLiClassName(
    offer.number,
    currentSlide,
    offersLength,
    activeOfferId,
  );

  const offerIsRentalService = helper.isRentalService(offer);
  const offerName = offerIsRentalService ? offer.rentalService.name : offer.accommodation.name;

  return (
    <li
      className={liClassNames}
    >
      <button
        type="button"
        className={btnClassNames}
        onClick={() => onOfferClicked(offer.id, offer.number)}
        onKeyPress={() => onOfferClicked(offer.id, offer.number)}
        title={offerName}
      >
        {offer.number}
      </button>
      <VotingShow votingState={votings[offer.id]} />
      <ViewedShow viewed={viewed && (!votings[offer.id] || votings[offer.id] === 'neutral')} />
    </li>
  );
};

const ClientOfferList = ({
  data, votes, onOfferClicked, currentSlide, navigation, viewed,
}) => (
  <ul className="ClientOfferList">
    {data && data.offers && data.offers.map(
      offer => (
        <Item
          key={offer.id}
          offer={offer}
          votings={votes}
          activeOfferId={navigation.offerId}
          onOfferClicked={onOfferClicked}
          currentSlide={currentSlide}
          offersLength={data && data.offers.length}
          viewed={viewed.includes(offer.id)}
        />
      ),
    )}
  </ul>
);

export default ClientOfferList;
