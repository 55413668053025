import React from 'react';

const SessionHeaderInformation = ({ session, onClick }) => (
  <button
    className="SessionHeaderInformation d-flex align-items-center"
    onClick={() => onClick(session)}
    type="button"
  >
    <h4 className="mb-0 mr-2">
      <span className="badge py-2 badge-danger">
        {session.name}
      </span>
    </h4>
  </button>
);

export default SessionHeaderInformation;
