import React from "react";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import ButtonWithoutDrag from "./ButtonWithoutDrag";
import OfferCard from "./OfferCard";

class OfferOverview extends React.PureComponent {
  componentDidMount() {
    const { match, onNavigate, matomoHandler } = this.props;

    matomoHandler(null, true);

    const offerNumber = Number(match.params.offerNumber);
    if (isNaN(offerNumber)) return;

    onNavigate(null, offerNumber, null);
  }

  componentDidUpdate(prevProps) {
    const { match, onNavigate } = this.props;

    if (prevProps.match.params.offerNumber !== match.params.offerNumber) {
      const offerNumber = Number(match.params.offerNumber);
      if (isNaN(offerNumber)) return;

      onNavigate(null, offerNumber, null);
      this.slider.slickGoTo(offerNumber - 1);
    }
  }

  onClickOfferLink = (offerId, sliderIndex) => {
    const { history, match, hideBackBeforeForceBtn, matomoHandler } =
      this.props;

    matomoHandler(offerId, false);
    hideBackBeforeForceBtn();
    history.push(
      `/${match.params.sessionKey}/offer/${offerId}/${sliderIndex + 1}`
    );
  };

  render() {
    const { offers, votings, match, viewedOffers, history, displayPrices } =
      this.props;
    const offerNumber = match.params.offerNumber
      ? Number(match.params.offerNumber)
      : 1;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: offers.length >= 3 ? 3 : offers.length,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      autoplay: false,
      autoplaySpeed: 3000,
      initialSlide: offerNumber,
      variableWidth: true,
      afterChange(currentSlider) {
        const offerNumberSlide = currentSlider + 1;

        if (Number(match.params.offerNumber) !== offerNumberSlide) {
          history.replace(`/${match.params.sessionKey}/${offerNumberSlide}`);
        }
      },
    };

    if (!offers) {
      return null;
    }

    return (
      <div className="OfferOverview">
        {/* eslint-disable-next-line no-return-assign */}
        <Slider ref={(slider) => (this.slider = slider)} {...settings}>
          {offers.map((offer, index) => (
            <ButtonWithoutDrag
              key={offer.id}
              onClick={() => this.onClickOfferLink(offer.id, index)}
            >
              <OfferCard
                offer={offer}
                votingState={votings[offer.id]}
                hasImage
                renderButton
                viewed={viewedOffers.includes(offer.id)}
                displayPrices={displayPrices}
                headerWithNav={false}
              />
            </ButtonWithoutDrag>
          ))}
        </Slider>
      </div>
    );
  }
}

export default withRouter(OfferOverview);
