import React from 'react';

class ButtonWithoutDrag extends React.Component {
  state = {
    position: null,
  };

  onMouseDown = (event) => {
    this.setState({
      position: {
        x: event.screenX,
        y: event.screenY,
      },
    });
  };

  onMouseUp = (event) => {
    const { onClick } = this.props;
    const { position } = this.state;

    if (!position) {
      return;
    }

    const diff = Math.sqrt(
      (position.x - event.screenX) ** 2,
      (position.y - event.screenY) ** 2,
    );

    if (diff < 6) {
      onClick();
    }

    this.setState({
      position: null,
    });
  };

  render() {
    const { children } = this.props;

    return (
      <button
        type="button"
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        className="btn-unstyled text-left w-100"
        role="link"
      >
        {children}
      </button>
    );
  }
}

export default ButtonWithoutDrag;
