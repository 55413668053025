import React from "react";

function Rate({ offer }) {
  return (
    <section id="services-vehicle" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Leistungen</h1>
      </header>
      <div className="row">
        <div className="col-12">
          <ul>
            {offer.rentalService.rate.rateServices &&
              offer.rentalService.rate.rateServices
                .slice(0)
                .map((rateService, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: rateService }}
                  />
                ))}
          </ul>
        </div>
        <div
          className="col-12"
          dangerouslySetInnerHTML={{ __html: offer.rentalService.description }}
        />
      </div>
    </section>
  );
}

export default Rate;
