import React from 'react';
import TeaserImage from './TeaserImage';

function Description({ offer, onNavigateGallery }) {
  const teaserImage = offer.accommodation.teaserImage && offer.accommodation.teaserImage.src;

  return (
    <section id="desc" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Beschreibung</h1>
      </header>
      <button type="button" onClick={() => onNavigateGallery(0)} className="btn-unstyled w-100 mb-3">
        <TeaserImage key={teaserImage} src={teaserImage} />
      </button>
      <div className="row">
        <div className="col-8" dangerouslySetInnerHTML={{ __html: offer.accommodation.description }} />
        <div className="col-4">
          {offer.accommodation.images.slice(1, 4).map((image, index) => (
            <button
              key={index}
              type="button"
              onClick={() => onNavigateGallery(index + 1)}
              className="btn-unstyled mb-3"
            >
              <img
                src={image.src}
                className="img-fluid"
                alt=""
              />
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Description;
