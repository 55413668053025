import React from "react";
import * as helper from "../../helper";
import { TransferModal } from "./TransferModal";

function Transfer({ transfers }) {
  return (
    <>
      {transfers.map((transfer) => (
        <div key={transfer.id} className="OfferDetail__sub-section">
          <div className="mb-4">
            <img
              className="d-block mb-3 additionals-img"
              src={transfer.image}
              alt=""
            />
            <div className="row pl-2 mb-2">
              <h3
                className="h4"
                dangerouslySetInnerHTML={{ __html: transfer.name }}
              ></h3>
            </div>
            <div className="row mb-2 pl-2">
              <div dangerouslySetInnerHTML={{ __html: transfer.description }} />
            </div>
            <div className="row pl-2 w-100 justify-content-between">
              <div className="align-self-end">
                <h5 className="mb-0">
                  {helper.convertISOCurrencyToSymbol(transfer.price)}
                </h5>
                <p>
                  {transfer.priceLabel ?? "Gesamtpreis"}{" "}
                  {transfer.showDuration && transfer.duration}
                </p>
              </div>
              <img
                className="additionals-logo"
                src={transfer.providerImage}
                alt={transfer.providerName}
              />
            </div>
            <br />
            {transfer.extendedDescription && (
              <TransferModal
                modalTitleText={transfer.name}
                modalBodyText={transfer.extendedDescription}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default Transfer;
