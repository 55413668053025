import React from 'react';
import SessionWidget from './SessionWidget';

const SessionList = ({ sessions, onSessionClick, onTerminateSessionClick }) => (
  <ul className="list-group list-group-flush">
    {sessions.map(session => (
      <SessionWidget
        key={session.key}
        session={session}
        onClick={onSessionClick}
        onTerminateSessionClick={() => onTerminateSessionClick(session.id)}
      />
    ))}
  </ul>
);

export default SessionList;
