import React from 'react';
import { ReactComponent as IconViewed } from '../../assets/img/check-solid.svg';

/* eslint react/no-did-update-set-state: "off" */

class ViewedShow extends React.PureComponent {
  state = {
    animate: false,
  };

  componentDidUpdate() {
    this.setState({
      animate: true,
    });
  }

  render() {
    const { viewed } = this.props;
    const { animate } = this.state;

    if (viewed === true) {
      return (
        <div className={`ViewedShow ${animate && ' ViewedShow--animate'}`}>
          <IconViewed />
        </div>
      );
    }

    return null;
  }
}

export default ViewedShow;
