import React from "react";
import * as semver from "semver";
import SessionOverview from "../components/clientWidget/SessionOverview";
import SessionControl from "../components/clientWidget/SessionControl";
import api from "../api";

/* eslint max-len: "off" */

class ClientWidget extends React.Component {
  constructor(props) {
    super(props);

    const { search } = window.location;
    const params = new URLSearchParams(search);

    const advisoryId = params.get("AdvisoryId") || null;
    const token = params.get("token") || null;
    const paxVersion = semver.coerce(params.get("plv") || "2.17.0").version;

    console.log("pax-version", paxVersion);

    this.state = {
      selectedSession: null,
      advisoryId,
      token,
      paxVersion,
    };

    const hasData = advisoryId || token;

    if (!hasData && process.env.NODE_ENV === "development") {
      window.location.replace(
        "http://localhost:3001/?plv=2.18&AdvisoryId=YYWC-9257&token=eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJjdXJyZW50LXRpbWUiOiIxNjAxMDI2MzI2IiwiYWdlbmN5LWlkIjoxfQ.4-m6NIlEndS_sM4_0BheLDMSe-3aWS5mZUDhqTnMKQ8"
      );
    }
  }

  onSessionClick = async (session) => {
    const { token, advisoryId } = this.state;

    if (!session.isRunning) {
      const advisoryData = await api.getAdvisory(advisoryId, token);

      await api.startSession(
        session.id,
        {
          advisor: `${advisoryData.agent.firstName} ${advisoryData.agent.lastName}`,
          advisoryId,
        },
        token
      );
    }

    this.setState({
      selectedSession: {
        id: session.id,
        key: session.key,
        name: session.name,
      },
    });
  };

  onSessionClosed = () => {
    this.setState({
      selectedSession: null,
    });
  };

  renderErrors() {
    const { advisoryId, token } = this.state;

    return (
      <div className="ErrorWrapperOverview">
        <h1>Die folgenden URL-Parameter fehlen:</h1>
        <ul>
          {!advisoryId && <li>AdvisoryId</li>}
          {!token && <li>token</li>}
        </ul>
      </div>
    );
  }

  render() {
    const { selectedSession, advisoryId, token, paxVersion } = this.state;

    if (!advisoryId || !token) {
      return this.renderErrors();
    }

    if (!selectedSession) {
      return (
        <SessionOverview
          onSessionClick={this.onSessionClick}
          advisoryId={advisoryId}
          paxVersion={paxVersion}
          token={token}
        />
      );
    }

    return (
      <SessionControl
        key={selectedSession.key}
        selectedSession={selectedSession}
        paxVersion={paxVersion}
        onSessionTerminate={this.onSessionClosed}
        token={token}
      />
    );
  }
}

export default ClientWidget;
