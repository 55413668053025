import React, { useState, useEffect, useRef } from "react";
import GoogleCookieOverlay from "../../components/smartApp/GoogleCookieOverlay";

export const GoogleMap = ({ lat, lng, googleMapsConfig }) => {
  if (googleMapsConfig.configuredGoogleMapsApiKey) {
    return (
      <GoogleMapStreetView
        lat={lat}
        lng={lng}
        apiKey={googleMapsConfig.configuredGoogleMapsApiKey}
        googleMapsCustomParameters={googleMapsConfig.googleMapsCustomParameters}
      />
    );
  } else if (googleMapsConfig.fallbackGoogleMapsApiKey) {
    return (
      <GoogleMapEmbedded
        lat={lat}
        lng={lng}
        apiKey={googleMapsConfig.fallbackGoogleMapsApiKey}
        googleMapsCustomParameters={googleMapsConfig.googleMapsCustomParameters}
      />
    );
  }

  return null;
};

const GoogleMapStreetView = ({
  lat,
  lng,
  apiKey,
  googleMapsCustomParameters,
}) => {
  const googleMapRef = useRef();

  useEffect(() => {
    var script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&language=${
      getQueryVariable("language") ?? "de"
    }`;
    script.async = true;
    script.defer = true;

    window.initMap = () => {
      const map = new window.google.maps.Map(googleMapRef.current, {
        zoom: Number(getQueryVariable("zoom") ?? 16),
        mapTypeId: getQueryVariable("maptype") ?? "roadmap",
        center: { lat, lng },
        disableDefaultUI: false,
      });

      new window.google.maps.Marker({
        position: { lat, lng },
        map: map,
      });
    };

    document.body.appendChild(script);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQueryVariable = (variable) => {
    if (googleMapsCustomParameters) {
      const params = googleMapsCustomParameters.split("&");

      for (var i = 0; i < params.length; i++) {
        var pair = params[i].split("=");
        if (decodeURIComponent(pair[0]) === variable) {
          return pair[1];
        }
      }
    }

    return null;
  };

  return (
    <div
      id="google-map"
      ref={googleMapRef}
      style={{ width: "100%", height: "450px" }}
    />
  );
};

const GoogleMapEmbedded = ({
  lat,
  lng,
  apiKey,
  googleMapsCustomParameters,
}) => {
  const getGoogleMapUrl = () => {
    let url = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${lat},${lng}`;

    if (googleMapsCustomParameters && googleMapsCustomParameters.length > 0)
      url += `&${googleMapsCustomParameters}`;

    return url;
  };

  return (
    <iframe
      title="google-map"
      src={getGoogleMapUrl()}
      width="100%"
      height="450"
      allowFullScreen
    />
  );
};

const Location = ({ offer, googleMapsConfig }) => {
  const [enabled, setEnabled] = useState(false);

  return (
    <section id="location" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h2 className="OfferDetail__section-headline h1">Lage</h2>
      </header>
      {enabled ? (
        <GoogleMap
          className="my-3"
          lat={Number(offer.accommodation.position.latitude)}
          lng={Number(offer.accommodation.position.longitude)}
          googleMapsConfig={googleMapsConfig}
        />
      ) : (
        <GoogleCookieOverlay setEnabled={setEnabled} />
      )}
    </section>
  );
};

export default Location;
