import React from "react";
import ClientNavList from "./Navigation";
import TeaserImage from "./TeaserImage";
import OfferType from "../common/OfferType";
import RatingStar from "../common/RatingStar";
import GalleryControl from "./GalleryControl";

import * as helper from "../../helper";

class OfferDetail extends React.PureComponent {
  onImageClick = () => {
    const { detailView, onBackToOverviewClicked, onDetailViewClicked } =
      this.props;

    if (detailView) {
      onBackToOverviewClicked();

      return;
    }

    onDetailViewClicked();
  };

  onToggleGallery = (state) => {
    const { onToggleGallery, onNavClicked, offer } = this.props;

    if (state === true) {
      onNavClicked("impressions", offer.id, offer.number);
    }
    onToggleGallery(state);
  };

  render() {
    const {
      offer,
      navigation,
      onNavClicked,
      onNavigateGallery,
      onCloseGallery,
      detailView,
    } = this.props;

    const offerIsCharter = helper.isCharter(offer);
    const offerIsRentalService = helper.isRentalService(offer);

    const service = offerIsRentalService
      ? offer.rentalService
      : offer.accommodation;
    let teaserImage = service.teaserImage && service.teaserImage.src;

    let galleryImage = null;

    if (
      Number.isInteger(navigation.context?.galleryImage) &&
      service.images.length > 0
    ) {
      galleryImage = Number(navigation.context.galleryImage);
      teaserImage = service.images[galleryImage].src;
    }

    const offerType = offer.bookingTypeLabel
      ? offer.bookingTypeLabel
      : offer.type;

    return (
      <React.Fragment>
        <div className="card-body">
          <h3 className="mb-0">
            Angebot Nr: {offer.number}
            <span className="font-weight-normal">
              {" "}
              / <OfferType>{offerType}</OfferType>
            </span>
          </h3>
        </div>
        <div className="position-relative">
          <TeaserImage
            key={teaserImage}
            src={teaserImage}
            onClick={this.onImageClick}
          />
          {!offerIsCharter && detailView && service.images.length > 0 && (
            <GalleryControl
              imageIndex={galleryImage}
              imagesLength={service.images.length}
              onNavigateGallery={onNavigateGallery}
              onCloseGallery={onCloseGallery}
            />
          )}
        </div>
        <div className="card-body">
          <h2 className="card-title">
            {service.name}
            {!offerIsRentalService && (
              <RatingStar category={service.ratingStars} />
            )}
          </h2>
          {offer.id === navigation.offerId && (
            <ClientNavList
              offer={offer}
              activeNavItemId={navigation.navigation}
              onNavClicked={onNavClicked}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default OfferDetail;
