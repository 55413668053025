import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import * as helper from "../../helper";

export const TransferModal = ({ modalTitleText, modalBodyText }) => {
  const [linkModal, setLinkModal] = useState(false);
  const [linkModalBody, setLinkModalBody] = useState();

  const toggleLinkModal = () => setLinkModal(!linkModal);

  const onLinkClick = () => {
    setLinkModalBody(
      <span
        dangerouslySetInnerHTML={{
          __html: helper.decodeHTMLEntities(modalBodyText),
        }}
      ></span>
    );
    toggleLinkModal();
  };

  return (
    <>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onLinkClick()}
        >
          WEITERE INFORMATIONEN
        </button>
      </div>
      <Modal
        modalClassName="Offer-Modal"
        isOpen={linkModal}
        toggle={toggleLinkModal}
      >
        <ModalHeader toggle={toggleLinkModal}>{modalTitleText}</ModalHeader>
        <ModalBody className="transferModalBody">{linkModalBody}</ModalBody>
      </Modal>
    </>
  );
};
