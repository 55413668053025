import React from 'react';
import { ReactComponent as IconHalfStar } from '../../assets/img/half-star.svg';
import { ReactComponent as IconStar } from '../../assets/img/star.svg';

function RatingStar({ category }) {
  switch (category) {
    case 'Category1':
      return (<div><IconStar /></div>);
    case 'Category1_5':
      return (<div><IconStar /><IconHalfStar /></div>);
    case 'Category2':
      return (<div><IconStar /><IconStar /></div>);
    case 'Category2_5':
      return (<div><IconStar /><IconStar /><IconHalfStar /></div>);
    case 'Category3':
      return (<div><IconStar /><IconStar /><IconStar /></div>);
    case 'Category3_5':
      return (<div><IconStar /><IconStar /><IconStar /><IconHalfStar /></div>);
    case 'Category4':
      return (<div><IconStar /><IconStar /><IconStar /><IconStar /></div>);
    case 'Category4_5':
      return (<div><IconStar /><IconStar /><IconStar /><IconStar /><IconHalfStar /></div>);
    case 'Category5':
      return (<div><IconStar /><IconStar /><IconStar /><IconStar /><IconStar /></div>);
    default:
      return '';
  }
}

export default RatingStar;
