import React from 'react';
import WelcomeScreensFooter from './WelcomeScreensFooter';

const WelcomeScreensWrapper = ({ children }) => (
  <React.Fragment>
    <main className="main -with-single-widget">
      <div className="background-animation-con" aria-hidden="true" />
      <div className="single-widget">
        {children}
      </div>
    </main>
    <WelcomeScreensFooter />
  </React.Fragment>
);

export default WelcomeScreensWrapper;
