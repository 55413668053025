import React from 'react';
import { ReactComponent as LoadingSvg } from '../../assets/img/loading.svg';

function Loading() {
  return (
    <div className="Loading">
      <LoadingSvg />
    </div>
  );
}

export default Loading;
