import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import OfferOverview from "./OfferOverview";
import InfoBar from "./InfoBar";
import themeApp from "../../themeApp";
import { ReactComponent as IconLogout } from "../../assets/img/logout.svg";
import { ReactComponent as PhoneAcceptIcon } from "../../assets/img/phone-solid.svg";
import { ReactComponent as PhoneDeclineIcon } from "../../assets/img/phone-slash-solid.svg";
import WelcomeScreensWrapper from "./WelcomeScreensWrapper";
import Video from "../common/Video";
import env from "../../env";
import api from "../../api";
import Modal from "../clientWidget/Modal";
import AgentImage from "./AgentImage";

import OfferDetailAccommodation from "./OfferDetailAccommodation";
import OfferDetailRentalService from "./OfferDetailRentalService";

import * as helper from "../../helper";
import { sendMatomoTrackingData } from "../common/MatomoTracking";

const REACT_APP_SMART_APP_URL = env("REACT_APP_SMART_APP_URL");

class Session extends React.Component {
  state = {
    advisoryId: null,
    data: null,
    token: null,
    previousPath: null,
    displayBackBeforeForceBtn: false,
    galleryImage: null,
    video: false,
    votes: {},
    visit: [],
    showPrices: false,
    showFullscreenVideo: false,
    notFound: false,
    agencyOverviewId: null,
    lastTrackingOP: null,
  };

  videoCallHolder = null;

  async componentDidMount() {
    const { match, history } = this.props;
    const { sessionKey } = match.params;
    const REACT_APP_SIGNALR_HUB_URL = env("REACT_APP_SIGNALR_HUB_URL");

    if (!sessionKey) {
      return;
    }

    this.signal = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_SIGNALR_HUB_URL)
      .build();

    this.signal.on("ReceiveStartSession", async (message) => {
      const parsedMessage = JSON.parse(message);

      const data = await api.getAdvisory(
        parsedMessage.advisoryId,
        parsedMessage.token
      );

      themeApp(data.portal);

      const votes = {};

      if (parsedMessage.votes) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of parsedMessage.votes) {
          votes[item.offerId] = item.vote;
        }
      }

      this.setState({
        advisoryId: parsedMessage.advisoryId,
        token: parsedMessage.token,
        data,
        visit: parsedMessage.visited.offerIds,
        showPrices: parsedMessage.settings.showPrices,
        showFullscreenVideo: parsedMessage.settings.showFullscreenVideo,
        votes,
        agencyOverviewId: this.props.location.state?.agencyOverviewId,
        pos: this.props.location.state?.pos,
      });
    });

    this.signal.on("ReceiveAdvisoryUpdated", async () => {
      const { advisoryId, token } = this.state;
      const newData = await api.getAdvisory(advisoryId, token);

      themeApp(newData.portal);

      this.setState({ data: newData });
    });

    this.signal.on("ReceiveStopSession", () => {
      const { advisoryId, data, agencyOverviewId, pos } = this.state;

      const baseUrl = data.portal.publicBaseUrl.replace(/"+$/g, "/");
      let redirectUrl = baseUrl + advisoryId;

      if (agencyOverviewId && !pos) {
        redirectUrl = `${REACT_APP_SMART_APP_URL}/overview/${agencyOverviewId}`;
        window.location.href = redirectUrl;
      }

      if (agencyOverviewId && pos) {
        redirectUrl = `${REACT_APP_SMART_APP_URL}/overview/${agencyOverviewId}/${pos}`;
        window.location.href = redirectUrl;
      }

      window.location.href = redirectUrl;
    });

    this.signal.on("ForceNavigate", async (message) => {
      const { displayBackBeforeForceBtn } = this.state;
      const nav = JSON.parse(message);

      if (displayBackBeforeForceBtn === false && nav.navigation === null) {
        this.setState({
          displayBackBeforeForceBtn: true,
          previousPath: history.location.pathname,
        });
      }

      if (!nav.offerId) {
        history.push(`/${sessionKey}/${nav.offerNumber}`);
      } else if (!nav.navigation) {
        history.push(`/${sessionKey}/offer/${nav.offerId}/${nav.offerNumber}`);
      } else {
        history.push(
          `/${sessionKey}/offer/${nav.offerId}/${nav.offerNumber}#${nav.navigation}`
        );
        this.scrollTo(nav.navigation);
      }

      if (Number.isInteger(nav.context?.galleryImage)) {
        this.setState({
          galleryImage: Number(nav.context.galleryImage),
        });
      } else {
        this.setState({
          galleryImage: null,
        });
      }
    });

    this.signal.on("Vote", (message) => {
      const { votes } = this.state;
      const data = JSON.parse(message);

      this.setState({
        votes: {
          ...votes,
          [data.offerId]: data.vote,
        },
      });
    });

    this.signal.on("ReceiveVisited", (message) => {
      const data = JSON.parse(message);

      this.setState({
        visit: data.offerIds,
      });
    });

    this.signal.on("ReceiveCallCommand", (message) => {
      const data = JSON.parse(message);

      if (data.command === "StartCall") {
        this.setState({
          video: true,
        });
      }

      if (data.command === "StopCall") {
        this.setState({
          video: false,
        });
      }

      if (data.command === "AcceptCall") {
        this.setState({
          video: false,
        });
      }
    });

    this.signal.on("ReceiveSessionSettingsUpdated", (message) => {
      const data = JSON.parse(message);

      this.setState({
        showPrices: data.showPrices,
        showFullscreenVideo: data.showFullscreenVideo,
      });

      this.setFullscreenElements(data.showFullscreenVideo);
    });

    this.signal.on("ReceiveError", (message) => {
      const error = JSON.parse(message);

      if (error.info.includes("CouldNotFindSessionId")) {
        this.setState({
          notFound: true,
        });

        this.signal.stop();
        this.signal = null;
      }
    });

    this.signal.onclose(this.connect);
    await this.connect();
  }

  handleMatomoTracking = (offerId, isOverview = false) => {
    if (!this.state.advisoryId) return;

    const lastTrackingOP = this.state.lastTrackingOP;

    let currentTrackingOP;

    if (offerId !== undefined && offerId)
      currentTrackingOP =
        this.props.match.params.sessionKey +
        (offerId ? `/offer/${offerId}` : ``);
    else currentTrackingOP = this.state.advisoryId;

    let view = "Overview";

    if (!isOverview && offerId) view = "Detail";

    if (!lastTrackingOP || lastTrackingOP !== currentTrackingOP) {
      sendMatomoTrackingData(
        "Customer",
        view,
        !isOverview && offerId
          ? `${this.state.advisoryId}/${offerId}`
          : `${this.state.advisoryId}`
      );
      this.setState({
        lastTrackingOP: currentTrackingOP,
      });
    }
  };

  componentWillUnmount() {
    if (this.signal) {
      this.signal.stop();
      this.signal = null;
    }
  }

  setFullscreenElements = (isFullscreen) => {
    if (!this.videoCallHolder) return;

    if (isFullscreen)
      this.videoCallHolder.style.cssText =
        "position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none";
    else this.videoCallHolder.style.cssText = "";
  };

  connect = async () => {
    if (!this.signal) {
      return;
    }

    try {
      await this.signal.start();
    } catch (err) {
      console.log(err);
      setTimeout(this.connect, 5000);
      return;
    }

    const { match } = this.props;
    const { sessionKey } = match.params;

    try {
      await this.signal.invoke(
        "RegisterConnectionAsync",
        JSON.stringify({
          Role: "Guest",
          SessionId: sessionKey,
          Preview: localStorage.getItem("preview") === "true",
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  scrollTo = (navItem) => {
    const scrollContainer = document.getElementById("scroll-container");
    let elemToScrollTo = document.getElementById(navItem);

    if (navItem === null) {
      // eslint-disable-next-line prefer-destructuring
      elemToScrollTo = document.getElementsByClassName("Scrollspy__item")[0];
    }

    if (elemToScrollTo) {
      scrollContainer.scrollTo(0, elemToScrollTo.offsetTop);
    }
  };

  vote = (offerId, vote) => {
    this.signal.invoke(
      "VoteAsync",
      JSON.stringify({
        offerId,
        vote,
      })
    );
  };

  navigate = (
    offerId = null,
    offerNumber = null,
    navigation = null,
    context = null
  ) => {
    this.signal.invoke(
      "NavigateAsync",
      JSON.stringify({
        offerId,
        offerNumber,
        navigation,
        context,
      })
    );
  };

  onGalleryClose = () => {
    this.setState({
      galleryImage: null,
    });
  };

  onNavigateGallery = (index) => {
    this.setState({
      galleryImage: index,
    });
  };

  hideBackBeforeForceBtn = () => {
    this.setState({
      displayBackBeforeForceBtn: false,
    });
  };

  onVideoClose = () => {
    this.setState({
      video: false,
    });
  };

  rediretToLogin = () => {
    const { history } = this.props;

    localStorage.removeItem("sessionKey");
    history.push("/");
  };

  onBackBeforeForce = () => {
    const { history } = this.props;
    const { previousPath } = this.state;

    this.setState({
      displayBackBeforeForceBtn: false,
    });

    history.push(previousPath);
  };

  getNotFoundMessage = (sessionKey) => {
    if (helper.isValidSessionKey(sessionKey))
      return (
        <span>
          smart-Beratung &quot;{sessionKey}&quot; <br /> wurde nicht gefunden
        </span>
      );

    return (
      <span>
        smart-Beratung <br /> wurde nicht gefunden
      </span>
    );
  };

  render() {
    const {
      advisoryId,
      data,
      token,
      votes,
      visit,
      displayBackBeforeForceBtn,
      video,
      galleryImage,
      showPrices,
      showFullscreenVideo,
      notFound,
    } = this.state;

    const { match } = this.props;
    const { sessionKey } = match.params;

    if (notFound) {
      return (
        <WelcomeScreensWrapper>
          <h1 className="h1 mb-3">{this.getNotFoundMessage(sessionKey)}</h1>
          <hr />
          <p>
            Bitte überprüfen Sie Ihren smart-Key <br /> und versuchen Sie es
            noch einmal.
          </p>
          <hr />
          <button
            type="button"
            onClick={() => this.rediretToLogin()}
            className="btn btn-secondary btn-block mt-3"
          >
            Zurück
          </button>
        </WelcomeScreensWrapper>
      );
    }

    if (!data) {
      return (
        <WelcomeScreensWrapper>
          <h1 className="h1 mb-3">
            Willkommen zu Ihrer
            <br />
            smart-Beratung
          </h1>
          <hr />
          <p>Bitte einen Moment noch ...</p>
          <hr />
          <button
            type="button"
            onClick={() => this.rediretToLogin()}
            className="btn btn-secondary btn-block mt-3"
          >
            Beenden
          </button>
        </WelcomeScreensWrapper>
      );
    }

    const preview = localStorage.getItem("preview") === "true";

    return (
      <React.Fragment>
        <header>
          {!preview && (
            <button
              type="button"
              title="smartberatung verlassen"
              onClick={() => this.rediretToLogin()}
              className="btn-logout"
            >
              <IconLogout />
            </button>
          )}
          {data.agent && (
            <InfoBar
              companyName={data.agency.name}
              agent={data.agent}
              advisoryId={advisoryId}
              portalImage={data.portal.portalBannerSmall}
              preview={preview}
            />
          )}
        </header>
        <main className="main bg-custom -with-navbar">
          {video && !preview && (
            <Call
              videoCallHolder={(el) => {
                this.videoCallHolder = el;
              }}
              signal={this.signal}
              onClose={this.onVideoClose}
              agent={data.agent}
              companyName={data.agency.name}
              showFullscreenVideo={showFullscreenVideo}
            />
          )}
          <Switch>
            <Route exact path="/:sessionKey/:offerNumber?">
              <OfferOverview
                offers={data.offers}
                vote={this.vote}
                votings={votes}
                onNavigate={this.navigate}
                viewedOffers={visit}
                displayBackBeforeForceBtn={displayBackBeforeForceBtn}
                onBackBeforeForce={this.onBackBeforeForce}
                hideBackBeforeForceBtn={this.hideBackBeforeForceBtn}
                displayPrices={showPrices}
                advisoryId={advisoryId}
                matomoHandler={this.handleMatomoTracking}
              />
            </Route>
            <Route path="/:sessionKey/offer/:id/:offerNumber">
              {/* eslint-disable-next-line no-shadow */}
              {({ match }) =>
                helper.isRentalService(data.offers, match.params.id) ? (
                  <OfferDetailRentalService
                    key={match.params.id}
                    offers={data.offers}
                    vote={this.vote}
                    votings={votes}
                    onNavigate={this.navigate}
                    onBack={this.hideBackBeforeForceBtn}
                    displayBackBeforeForceBtn={displayBackBeforeForceBtn}
                    onBackBeforeForce={this.onBackBeforeForce}
                    bookingLink={`${data.portal.publicBaseUrl.replace(
                      /\/$/,
                      ""
                    )}/${advisoryId}/${match.params.id}/`}
                    displayPrices={showPrices}
                    onGalleryClose={this.onGalleryClose}
                    onNavigateGallery={this.onNavigateGallery}
                    galleryImage={galleryImage}
                    preview={preview}
                    googleMapsConfig={data.agency.googleMaps}
                    googleMapsCustomParameters={
                      data.agency.googleMapsCustomParameters
                    }
                    portalImage={data.portal.portalBannerSmall}
                    matomoHandler={this.handleMatomoTracking}
                  />
                ) : (
                  <OfferDetailAccommodation
                    key={match.params.id}
                    offers={data.offers}
                    vote={this.vote}
                    votings={votes}
                    onNavigate={this.navigate}
                    onBack={this.hideBackBeforeForceBtn}
                    displayBackBeforeForceBtn={displayBackBeforeForceBtn}
                    onBackBeforeForce={this.onBackBeforeForce}
                    bookingLink={`${data.portal.publicBaseUrl.replace(
                      /\/$/,
                      ""
                    )}/${advisoryId}/${match.params.id}/`}
                    displayPrices={showPrices}
                    onGalleryClose={this.onGalleryClose}
                    onNavigateGallery={this.onNavigateGallery}
                    galleryImage={galleryImage}
                    token={token}
                    preview={preview}
                    googleMapsConfig={data.agency.googleMaps}
                    googleMapsCustomParameters={
                      data.agency.googleMapsCustomParameters
                    }
                    portalImage={data.portal.portalBannerSmall}
                    matomoHandler={this.handleMatomoTracking}
                  />
                )
              }
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

class Call extends React.Component {
  state = {
    call: false,
  };

  onCall = async () => {
    const { signal } = this.props;

    this.setState({
      call: true,
    });

    try {
      await signal.invoke(
        "SendCallCommandAsync",
        JSON.stringify({
          command: "AcceptCall",
        })
      );
    } catch (e) {
      // do nothing
    }
  };

  onClose = () => {
    const { signal, onClose } = this.props;

    this.setState({
      call: false,
    });

    signal.invoke(
      "SendCallCommandAsync",
      JSON.stringify({
        command: "StopCall",
        data: "",
      })
    );

    onClose();
  };

  render() {
    const { call } = this.state;
    const { signal, agent, companyName, showFullscreenVideo, videoCallHolder } =
      this.props;

    if (call) {
      return (
        <React.Fragment>
          <div className="VideoCall" ref={videoCallHolder}>
            <Video
              signal={signal}
              initiator={false}
              showFullscreenVideo={showFullscreenVideo}
              onClose={this.onClose}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <Modal alternative>
        <div className="incoming-call">
          <div className="incoming-call__caller-info">
            <div className="text-center">
              <AgentImage path={agent.image} />
              <div className="incoming-call__caller-name">
                {agent.firstName} {agent.lastName}
              </div>
              {companyName}
              <br />
              <span className="incoming-call__label">ruft an</span>
            </div>
          </div>
          <div className="incoming-call__btn-con">
            <div className="text-center">
              <button
                type="button"
                className="incoming-call__btn incoming-call__btn--accept"
                onClick={this.onCall}
              >
                <PhoneAcceptIcon />
              </button>
              <p className="mb-0">Annehmen</p>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="incoming-call__btn incoming-call__btn--decline"
                onClick={this.onClose}
              >
                <PhoneDeclineIcon />
              </button>
              <p className="mb-0">Ablehnen</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(Session);
