import React from "react";
import Tooltip from "./Tooltip";

class IconWithTooltip extends React.PureComponent {
  state = {
    show: false,
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  toggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    const { show } = this.state;
    const { icon, children } = this.props;

    return (
      <React.Fragment>
        <span className="icon" ref={this.iconRef}>
          {icon}
        </span>
        <Tooltip
          target={this.iconRef}
          placement="top"
          isOpen={show}
          toggle={this.toggle}
          {...this.props}
        >
          {children}
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default IconWithTooltip;
