import React from 'react';
import SessionListElement from './SessionListElement';
import Modal from './Modal';
import { ReactComponent as Close } from '../../assets/img/times-light.svg';

class SessionWidget extends React.Component {
  state = {
    displayModal: false,
  };

  openModal = () => {
    this.setState({
      displayModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      displayModal: false,
    });
  };

  onTerminate = () => {
    const { onTerminateSessionClick } = this.props;

    this.setState({
      displayModal: false,
    });

    onTerminateSessionClick();
  };

  render() {
    const { session, onClick } = this.props;
    const { displayModal } = this.state;

    return (
      <div className="mt-3">
        {session.isRunning
          && (
            <div>
              {displayModal
                && (
                  <Modal closeModal={this.closeModal}>
                    <p>Sind Sie sicher das Sie die smart-Beratung beenden wollen?</p>
                    <button
                      className="ClientWidget__modalBtn"
                      type="button"
                      onClick={this.onTerminate}
                    >
                      smart-Beratung beenden
                    </button>
                  </Modal>
                )
              }
              <button
                onClick={this.openModal}
                title="smart-Beratung beenden"
                type="button"
                className="CircleButton SessionCloseBtn"
              >
                <Close height={18} width={18} />
              </button>
            </div>
          )
        }
        <SessionListElement session={session} onClick={onClick} displaySessionKey />
      </div>
    );
  }
}

export default SessionWidget;
