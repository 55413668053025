import React from 'react';

const DisplayError = ({children}) => {
  return (
    <div className="ErrorWrapperOverview">
      <h1>Fehlermeldung</h1>
      <ul className="list-unstyled">
          <li>{children || 'Es ist ein unbekannter Fehler aufgetreten.'}</li>
      </ul>
    </div>
  );
};

export default DisplayError;
