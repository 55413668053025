import React from "react";
import moment from "moment";

import IframeResizer from "iframe-resizer-react";

function colorPicker(value) {
  if (value <= 67) {
    return "red";
  }

  if (value > 67 && value < 74) {
    return "yellow";
  }

  return "green";
}

const trustYouLogo =
  "https://cdn.smartberatung.com/logos/addoncontent/trustyou.png";

const RatingTrustYou = ({ hotel, rating }) => {
  const parsedHotel = JSON.parse(hotel);
  const parsedRating = JSON.parse(rating);

  const data = {
    info: parsedHotel.response,
    ratings: parsedRating.response,
  };

  const widgetUrl = `https://api.trustyou.com/hotels/mappings/giata/${parsedHotel.giataId}/meta_review.html?lang=de&iframe_resizer=true&key=e135107d-fac9-4737-931e-89dce9b091f7`;

  if (!data.ratings.category_list) {
    return (
      <div className="OfferDetail__sub-section">
        <div className="row">
          <div className="col-4 mb-3">
            <img className="RatingTrustYou__logo" src={trustYouLogo} alt="" />
          </div>
        </div>
        <div className="RatingError">Es wurden keine Bewertungen gefunden.</div>
      </div>
    );
  }

  return (
    <div className="OfferDetail__sub-section">
      <div className="row">
        <div className="col-4 mb-3">
          <img className="RatingTrustYou__logo" src={trustYouLogo} alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="RatingTrustYou__overall-wrapper">
            Gesamtbewertung
            <br />
            {data.info.score_description}
          </div>
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col-6">
          <p className="mb-0">{data.info.reviews_count} Bewertungen</p>
        </div>
      </div>
      {Object.entries(data.ratings.category_list).map(([key, info]) => (
        <div key={key} className="row mb-2">
          <div className="col-6">{info.category_name}</div>
          <div className="col-6 d-flex justify-content-end">
            <div className="RatingTrustYou__rating-bar-background w-100">
              <div
                className={`RatingTrustYou__bar RatingTrustYou--${colorPicker(
                  info.score
                )}`}
                style={{ width: `${info.score}%` }}
              />
            </div>
            <div className="ml-3">{info.score}</div>
          </div>
        </div>
      ))}
      <p>&copy; {moment().format("Y")} TrustYou</p>

      <IframeResizer
        src={widgetUrl}
        allowtransparency="true"
        width="99%"
        scrolling="no"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="TrustYou Iframe"
        checkOrigin={false}
      />
    </div>
  );
};

export default RatingTrustYou;
