import React from "react";
import debounce from "lodash/debounce";
import Loading from "../common/Loading";
import ClientOfferList from "./ClientOfferList";
import OfferDetail from "./OfferDetail";
import OfferControls from "./OfferControls";

function OfferWrapper({
  data,
  viewed,
  votes,
  navigation,
  onNavClicked,
  onOfferClicked,
  onBackToOverviewClicked,
  onSliderChangeClicked,
  onNavigateGallery,
  onCloseGallery,
  advisoryId,
}) {
  if (!data) {
    return (
      <div className="card mb-1">
        <div className="card-body">
          <Loading />
        </div>
      </div>
    );
  }

  const offerCount = (data && data.offers.length) || 0;
  const currentOfferNumber = Math.min(
    (navigation && navigation.offerNumber) || 1,
    offerCount
  );
  const findCurrentOfferFunction = (offer) =>
    offer.id === navigation.offerId || offer.number === currentOfferNumber;
  const currentOffer =
    data && data.offers && data.offers.find(findCurrentOfferFunction);
  const detailView = !!(navigation && navigation.offerId);

  const onOfferControlsButtonClicked = (offerNumber) => {
    const navItem = null;
    let navigateToOfferId = null;
    let nextOfferNumber = offerNumber;

    if (nextOfferNumber > offerCount) {
      nextOfferNumber = 1;
    }

    if (nextOfferNumber <= 0) {
      nextOfferNumber = offerCount;
    }

    if (detailView === true) {
      navigateToOfferId =
        data &&
        data.offers &&
        data.offers.find((offer) => offer.number === nextOfferNumber).id;
    }

    onSliderChangeClicked(
      navItem,
      navigateToOfferId,
      nextOfferNumber,
      detailView
    );
  };

  const onOfferControlsButtonClickedBounced = debounce(
    onOfferControlsButtonClicked,
    250
  );

  const onOfferControlsButtonClickedLeft = () => {
    onOfferControlsButtonClickedBounced(currentOfferNumber - 1);
  };

  const onOfferControlsButtonClickedRight = () => {
    onOfferControlsButtonClickedBounced(currentOfferNumber + 1);
  };

  const onDetailViewClicked = () => {
    const navItem = null;
    let navigateToOfferId = null;

    navigateToOfferId =
      data &&
      data.offers &&
      data.offers.find((offer) => offer.number === currentOfferNumber).id;

    if (navigateToOfferId !== null) {
      onSliderChangeClicked(
        navItem,
        navigateToOfferId,
        currentOfferNumber,
        true
      );
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <ClientOfferList
          data={data}
          viewed={viewed}
          votes={votes}
          onOfferClicked={onOfferClicked}
          currentSlide={currentOfferNumber}
          navigation={navigation}
        />
        <div className="card mt-2">
          <OfferControls
            detailView={detailView}
            onBackToOverviewClicked={onBackToOverviewClicked}
            onDetailViewClicked={onDetailViewClicked}
            onSliderChangeClickedLeft={onOfferControlsButtonClickedLeft}
            onSliderChangeClickedRight={onOfferControlsButtonClickedRight}
          />
          {currentOffer && (
            <OfferDetail
              offer={currentOffer}
              data={data}
              navigation={navigation}
              onNavClicked={onNavClicked}
              detailView={detailView}
              onBackToOverviewClicked={onBackToOverviewClicked}
              onDetailViewClicked={onDetailViewClicked}
              onNavigateGallery={onNavigateGallery}
              onCloseGallery={onCloseGallery}
              advisoryId={advisoryId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default OfferWrapper;
