import React from 'react';
import moment from 'moment';
import ContentModal from './ContentModal';

function WelcomeScreensFooter() {
  const year = moment().year();

  return (
    <footer className="WelcomeScreensFooter">
      <div className="text-white">© 2009 - {year} paxconnect GmbH</div>
      <div className="WelcomeScreensFooter__links">
        <ContentModal
          url="/v1/SmartBeratung/Info/Privacy"
          btnText="Datenschutzerklärung"
        />
        <span className="WelcomeScreensFooter__pipe"> | </span>
        <ContentModal
          url="/v1/SmartBeratung/Info/Imprint"
          btnText="Impressum"
        />
      </div>
    </footer>
  );
}

export default WelcomeScreensFooter;
