import React from 'react';
import OfferCardAccommodation from './OfferCardAccommodation';
import OfferCardRentalService from './OfferCardRentalService';

import * as helper from '../../helper';

const OfferCard = ({
  offer,
  votingState,
  hasImage,
  vote,
  hasVotingButton,
  renderButton,
  viewed,
  displayPrices,
  changeOffer,
  headerWithNav = false,
  detail = false
}) => (
  helper.isRentalService(offer) 
    ? <OfferCardRentalService 
        offer={offer}
        votingState={votingState}
        hasImage={hasImage}
        vote={vote}
        hasVotingButton={hasVotingButton}
        renderButton={renderButton}
        viewed={viewed}
        displayPrices={displayPrices}
        changeOffer={changeOffer}
        headerWithNav={headerWithNav}
        detail={detail}
      /> 
    : <OfferCardAccommodation 
        offer={offer}
        votingState={votingState}
        hasImage={hasImage}
        vote={vote}
        hasVotingButton={hasVotingButton}
        renderButton={renderButton}
        viewed={viewed}
        displayPrices={displayPrices}
        changeOffer={changeOffer}
        headerWithNav={headerWithNav}
        detail={detail}
      />
);

export default React.memo(OfferCard);
