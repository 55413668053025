import React from "react";
import RatingTrustYou from "./RatingTrustYou";
import RatingHolidayCheck from "./RatingHolidayCheck";

function Rating({ offerRatingProvider }) {
  if (
    !offerRatingProvider?.holidayCheckHotel &&
    !offerRatingProvider?.holidayCheckHotelV3 &&
    !offerRatingProvider?.trustYouHotel
  )
    return null;

  return (
    <section id="rating" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h2 className="OfferDetail__section-headline h1">Bewertungen</h2>
      </header>
      {offerRatingProvider.trustYouHotel && (
        <RatingTrustYou
          hotel={offerRatingProvider.trustYouHotel}
          rating={offerRatingProvider.trustYouRating}
        />
      )}
      {offerRatingProvider.holidayCheckHotel ||
        (offerRatingProvider.holidayCheckHotelV3 && (
          <RatingHolidayCheck
            hotelV3={offerRatingProvider.holidayCheckHotelV3}
            hotel={offerRatingProvider.holidayCheckHotel}
            rating={offerRatingProvider.holidayCheckRating}
          />
        ))}
    </section>
  );
}

export default Rating;
