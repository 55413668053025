import React, { useEffect } from "react";
import moment from "moment";

import IconWithTooltip from "../common/IconWithTooltip";

import { ReactComponent as Plane } from "../../assets/img/plane-solid.svg";
import { ReactComponent as Info } from "../../assets/img/info-circle-solid.svg";

const Flights = ({ offer }) => {
  let hasFlightInfos = false;

  offer.flights.forEach((x) => {
    if (x.serviceDescription && x.serviceDescription.length > 0) {
      hasFlightInfos = true;
      return;
    }
  });

  useEffect(() => {
    renderLayOverArrows();
    window.addEventListener("resize", renderLayOverArrows);
  });

  const getDurationString = (inputTimeInImutes) => {
    const hours = parseInt(inputTimeInImutes / 60, 10);
    const minutes = inputTimeInImutes % 60;

    return `${hours.toString().padStart(2, "0")} Std. ${minutes
      .toString()
      .padStart(2, "0")} Min.`;
  };

  const getFlightDuration = (flight) => {
    const { flightTimeInMinutes } = flight;

    if (flightTimeInMinutes === null) {
      return null;
    }

    return (
      <div className="flight-duration">
        {getDurationString(flightTimeInMinutes)}
      </div>
    );
  };

  const getOverallFlightTime = (flights) => {
    let overallTime = 0;

    flights.forEach(
      (x) => (overallTime += x.flightTimeInMinutes + x.stopoverTimeInMinutes)
    );

    const showTraveTime = overallTime > 0;

    return (
      <>
        {showTraveTime && (
          <div className="flight-overall-time">
            Gesamtreisezeit <b>{getDurationString(overallTime)}</b>
          </div>
        )}
      </>
    );
  };

  const renderPlaneIcon = (flight) => {
    if (flight.flightType === "Outward") {
      return (
        <Plane
          height={12}
          className="mr-1"
          style={{ transform: "rotate(-35deg)" }}
        />
      );
    }

    if (flight.flightType === "Inward") {
      return (
        <Plane
          height={12}
          className="mr-1"
          style={{ transform: "rotate(-145deg)" }}
        />
      );
    }

    return null;
  };

  const renderFlightHeader = (flight) => {
    if (flight.flightType === "Outward") {
      return (
        <span>
          Hinflug am{" "}
          {moment.utc(flight.startDate).locale("de").format("Do MMMM YYYY")}
        </span>
      );
    }

    if (flight.flightType === "Inward") {
      return (
        <span>
          Rückflug am{" "}
          {moment.utc(flight.endDate).locale("de").format("Do MMMM YYYY")}
        </span>
      );
    }

    return "FLIGHTTYPE NOT DEFINED (Outward/Inward)";
  };

  const renderAirlineLogo = (flight) => {
    if (!flight.airlineLogo) return null;

    return (
      <img
        className="float-right"
        style={{ maxHeight: "18px", maxWidth: "100px" }}
        src={flight.airlineLogo}
        alt="Logo der Fluggesellschaft"
        title="Logo der Fluggesellschaft"
      />
    );
  };

  const isInvalid = (flight) => {
    const invalidCarrier =
      !flight.carrier ||
      flight.carrier.indexOf("Flugzeiten noch nicht verfügbar") !== -1;
    const invalidFlightNumber = !flight.flightNumber;

    const invalidStart =
      moment.utc(flight.startDate).hours() === 0 &&
      moment.utc(flight.startDate).minutes() === 0;

    const invalidEnd =
      moment.utc(flight.endDate).hours() === 0 &&
      moment.utc(flight.endDate).minutes() === 0;

    const invalidFlightDuration =
      !flight.flightTimeInMinutes || flight.flightTimeInMinutes <= 10;

    return (
      (invalidStart && invalidEnd) ||
      (invalidCarrier && invalidFlightDuration) ||
      (invalidFlightNumber && invalidFlightDuration)
    );
  };

  const renderFlight = (flight) => {
    let serviceDescriptionFirst = "";
    let serviceDescriptionTxt = "";

    if (flight.serviceDescription) {
      flight.serviceDescription
        .split("\n")
        .forEach((x) => (serviceDescriptionTxt += " • " + x + "\r\n"));
      serviceDescriptionFirst = flight.serviceDescription.split("\n", 1);
    }

    return (
      <table key={flight.id} className="table table-sm flight-info">
        <colgroup>
          <col className="w-33" />
          <col />
        </colgroup>

        <thead>
          <tr>
            <th colSpan="2" className="border-top-0">
              {renderPlaneIcon(flight)}
              {renderFlightHeader(flight)}
              {renderAirlineLogo(flight)}
            </th>
          </tr>
        </thead>
        <tbody>
          {flight.departureAirport && (
            <tr>
              <td>von</td>
              <td>
                {flight.departureAirport.iata} ({flight.departureAirport.name})
              </td>
            </tr>
          )}
          {flight.arrivalAirport && (
            <tr>
              <td>nach</td>
              <td>
                {flight.arrivalAirport.iata} ({flight.arrivalAirport.name})
              </td>
            </tr>
          )}
          {isInvalid(flight) ? (
            <>
              <tr>
                <td>Flug</td>
                <td>Flugdetails unbekannt</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>Flug</td>
                <td>
                  {flight.carrier} {flight.flightNumber}
                </td>
              </tr>
              <tr>
                <td>Abflug</td>
                <td className="position-relative">
                  <b>
                    {moment.utc(flight.startDate).locale("de").format("LT")}{" "}
                  </b>
                  {getFlightDuration(flight)}
                </td>
              </tr>
              <tr>
                <td>Ankunft</td>
                <td>
                  <b>
                    {moment.utc(flight.endDate).locale("de").format("LT") +
                      (moment.utc(flight.endDate).date() >
                      moment.utc(flight.startDate).date()
                        ? " (+1)"
                        : "")}
                  </b>
                </td>
              </tr>
              <tr>
                <td>Flugklasse</td>
                <td>{flight.optionalFlightClass}</td>
              </tr>
            </>
          )}
          {hasFlightInfos && (
            <tr>
              <td className="flight-info__last-td-element">Fluginfos</td>
              <td className="flight-info__text-truncate flight-info__last-td-element">
                {flight.serviceDescription ? (
                  <span>
                    {serviceDescriptionFirst}
                    <IconWithTooltip icon={<Info />}>
                      {serviceDescriptionTxt}
                    </IconWithTooltip>
                  </span>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          )}
          {flight.stopoverTimeInMinutes > 0 && !isInvalid(flight) && (
            <tr>
              <td>Aufenthalt</td>
              <td>
                <div className="stopover-time">
                  {getDurationString(flight.stopoverTimeInMinutes)}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const renderLayOverArrows = () => {
    const stopoverBoxes = document.getElementsByClassName("stopover-time");
    const canvas = document.getElementsByClassName(
      "OfferDetail__flight-canvas"
    )[0];

    if (!canvas || !stopoverBoxes) return;

    canvas.width = document
      .getElementById("flight-tables")
      .getBoundingClientRect().width;
    canvas.height = document
      .getElementById("flight-tables")
      .getBoundingClientRect().height;

    const ctx = canvas.getContext("2d");

    Array.from(stopoverBoxes).forEach((x) => {
      const parentRect = document
        .getElementById("flight-tables")
        .getBoundingClientRect();
      const childRect = x.getBoundingClientRect();

      const posyfb = childRect.top - parentRect.top;
      const posxfb = childRect.left - parentRect.left;
      const heightfb = childRect.height;
      const widthfb = childRect.width;

      // top arrow
      let fromx = childRect.left - parentRect.left + childRect.width / 2;
      let fromy =
        childRect.top -
        parentRect.top -
        0.3 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      let tox = childRect.left - parentRect.left + childRect.width / 2;
      let toy = childRect.top - parentRect.top;

      let arrowSize = 1;
      let headlen = 0;

      let angle = Math.atan2(toy - fromy, tox - fromx);
      //starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath();
      ctx.moveTo(fromx, fromy);
      ctx.lineTo(tox, toy);
      ctx.strokeStyle = "#e1e1e1";
      ctx.lineWidth = arrowSize;
      ctx.stroke();

      // bottom arrow
      fromx = posxfb + widthfb / 2;
      fromy = posyfb + heightfb;
      tox = posxfb + widthfb / 2;
      toy = posyfb + heightfb / 2 + 30;

      arrowSize = 1;
      headlen = 10;

      angle = Math.atan2(toy - fromy, tox - fromx);
      //starting path of the arrow from the start square to the end square and drawing the stroke
      ctx.beginPath();
      ctx.moveTo(fromx, fromy);
      ctx.lineTo(tox, toy);
      ctx.strokeStyle = "#e1e1e1";
      ctx.lineWidth = arrowSize;
      ctx.stroke();

      //starting a new path from the head of the arrow to one of the sides of the point
      ctx.beginPath();
      ctx.moveTo(tox, toy);
      ctx.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 7),
        toy - headlen * Math.sin(angle - Math.PI / 7)
      );

      //path from the side point of the arrow, to the other side point
      ctx.lineTo(
        tox - headlen * Math.cos(angle + Math.PI / 7),
        toy - headlen * Math.sin(angle + Math.PI / 7)
      );

      //path from the side point back to the tip of the arrow, and then again to the opposite side point
      ctx.lineTo(tox, toy);
      ctx.lineTo(
        tox - headlen * Math.cos(angle - Math.PI / 7),
        toy - headlen * Math.sin(angle - Math.PI / 7)
      );

      //draws the paths created above
      ctx.strokeStyle = "#e1e1e1";
      ctx.lineWidth = arrowSize;
      ctx.stroke();
      ctx.fillStyle = "#424242";
      ctx.fill();
    });
  };

  const renderFlights = (offer) => {
    const outwardFlights = offer.flights.filter(
      (x) => x.flightType === "Outward"
    );
    const inwardFlights = offer.flights.filter(
      (x) => x.flightType === "Inward"
    );

    return (
      <div id="flight-tables" className="row">
        <div className="col-lg-6">
          {outwardFlights.map((flight) => renderFlight(flight))}
          <div>{getOverallFlightTime(outwardFlights)}</div>
        </div>
        <div className="col-lg-6">
          {inwardFlights.map((flight) => renderFlight(flight))}
          <div>{getOverallFlightTime(inwardFlights)}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="OfferDetail__section" id="flights">
        <header className="OfferDetail__section-header">
          <h1 className="OfferDetail__section-headline h1">Ihr Flug</h1>
        </header>
        <canvas className="OfferDetail__flight-canvas"></canvas>
        {renderFlights(offer)}
      </section>
    </>
  );
};

export default Flights;
