import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../assets/img/chevron-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/chevron-right.svg';
import { ReactComponent as ListIcon } from '../../assets/img/list-light.svg';
import { ReactComponent as DetailViewIcon } from '../../assets/img/file-light.svg';

function OfferControls({
  detailView,
  onBackToOverviewClicked,
  onDetailViewClicked,
  onSliderChangeClickedLeft,
  onSliderChangeClickedRight,
}) {
  function renderBackButton() {
    if (detailView === false) {
      return (
        <button
          type="button"
          className="OfferControls__button OfferControls__back-button"
          onClick={onDetailViewClicked}
        >
          <DetailViewIcon /> Zur Detailseite
        </button>
      );
    }

    return (
      <button
        type="button"
        className="OfferControls__button OfferControls__back-button"
        onClick={onBackToOverviewClicked}
      >
        <ListIcon /> Zur Angebotsliste
      </button>
    );
  }

  return (
    <div className="OfferControls">
      <button
        type="button"
        className="OfferControls__button OfferControls__arrow-button"
        onClick={() => onSliderChangeClickedLeft()}
      >
        <ArrowLeftIcon />
      </button>
      {renderBackButton()}
      <button
        type="button"
        className="OfferControls__button OfferControls__arrow-button"
        onClick={() => onSliderChangeClickedRight()}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
}

export default OfferControls;
