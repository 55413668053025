import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../assets/img/chevron-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/chevron-right.svg';
import OfferType from '../common/OfferType';

function OfferCardHeaderWithNav({ offer, changeOffer }) {
  const offerType = offer.bookingTypeLabel ? offer.bookingTypeLabel : offer.type;
  return (
    <div className="Offer__card-body--flex card-body">
      <button
        type="button"
        className="OfferControls__button OfferControls__arrow-button text-secondary"
        onClick={() => changeOffer(offer.number - 1)}
      >
        <ArrowLeftIcon />
      </button>
      <h3 className="h3 m-0">
        Angebot Nr: {offer.number}
        <span className="font-weight-normal"> / <OfferType>{offerType}</OfferType></span>
      </h3>
      <button
        type="button"
        className="OfferControls__button OfferControls__arrow-button text-secondary"
        onClick={() => changeOffer(offer.number + 1)}
      >
        <ArrowRightIcon />
      </button>
    </div>
  );
}

export default OfferCardHeaderWithNav;
