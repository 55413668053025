import React from "react";
import * as helper from "../../helper";

function Item({ id, activeId, onClick, children }) {
  return (
    <li id={id} className="ClientNavList__item">
      <button
        className={`ClientNavList__btn btn-sm ${
          id === activeId ? "ClientNavList__btn--active" : ""
        }`}
        type="button"
        onClick={() => onClick(id)}
      >
        {children}
      </button>
    </li>
  );
}

const Navigation = ({ offer, activeNavItemId, onNavClicked }) => {
  const onClick = (itemId) => {
    onNavClicked(itemId, offer.id, offer.number);
  };

  return (
    <ul className="ClientNavList list-unstyled mb-0">
      {helper.hasAttributes(offer) && (
        <Item id="attributes" activeId={activeNavItemId} onClick={onClick}>
          Info zum Angebot
        </Item>
      )}

      {!helper.isRentalService(offer) && helper.hasDescription(offer) && (
        <Item id="desc" activeId={activeNavItemId} onClick={onClick}>
          Beschreibung
        </Item>
      )}

      {!helper.isRentalService(offer) && helper.hasFlights(offer) && (
        <Item id="flights" activeId={activeNavItemId} onClick={onClick}>
          Ihr Flug
        </Item>
      )}

      {!helper.isRentalService(offer) && helper.hasLocation(offer) && (
        <Item id="location" activeId={activeNavItemId} onClick={onClick}>
          Lage
        </Item>
      )}
      {helper.hasRentalServiceVehicle(offer) && (
        <Item
          id="features-vehicle"
          activeId={activeNavItemId}
          onClick={onClick}
        >
          Fahrzeug
        </Item>
      )}

      {helper.hasRentalServiceRateOrDescription(offer) && (
        <Item
          id="services-vehicle"
          activeId={activeNavItemId}
          onClick={onClick}
        >
          Leistungen
        </Item>
      )}

      {helper.hasPickUpLocation(offer) &&
        helper.hasDropOffLocation(offer) &&
        helper.pickUpDropOffIdentical(offer) && (
          <Item
            id="single-car-location"
            activeId={activeNavItemId}
            onClick={onClick}
          >
            Übernahme/Rückgabe
          </Item>
        )}

      {helper.hasPickUpLocation(offer) &&
        !helper.pickUpDropOffIdentical(offer) && (
          <Item
            id="pickup-location"
            activeId={activeNavItemId}
            onClick={onClick}
          >
            Übernahme
          </Item>
        )}

      {helper.hasDropOffLocation(offer) &&
        !helper.pickUpDropOffIdentical(offer) && (
          <Item
            id="dropoff-location"
            activeId={activeNavItemId}
            onClick={onClick}
          >
            Rückgabe
          </Item>
        )}

      {!helper.isCharter(offer) && helper.hasImpressions(offer) && (
        <Item id="impressions" activeId={activeNavItemId} onClick={onClick}>
          Impressionen
        </Item>
      )}

      {helper.hasVideos(offer) && (
        <Item id="videos" activeId={activeNavItemId} onClick={onClick}>
          {offer.videos.length > 1 ? "Videos" : "Video"}
        </Item>
      )}

      {!helper.isRentalService(offer) && helper.hasRating(offer) && (
        <Item id="rating" activeId={activeNavItemId} onClick={onClick}>
          Bewertungen
        </Item>
      )}

      {helper.hasServices(offer) && (
        <Item id="services" activeId={activeNavItemId} onClick={onClick}>
          Zusatzangebote
        </Item>
      )}

      {helper.hasInsurance(offer) && (
        <Item id="insurance" activeId={activeNavItemId} onClick={onClick}>
          Reiseschutz
        </Item>
      )}

      {!helper.isRentalService(offer) && helper.hasAtmosfairs(offer) && (
        <Item id="atmosfairs" activeId={activeNavItemId} onClick={onClick}>
          CO2-Kompensieren
        </Item>
      )}
    </ul>
  );
};

export default Navigation;
