import { useState } from "react";
import { Modal } from "reactstrap";

import IframeResizer from "iframe-resizer-react";

export const ContentModalIFrame = ({ url, btnText, className, title }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="text-center">
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => setModalOpen(true)}
      >
        {btnText}
      </button>

      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        className={className}
      >
        <IframeResizer
          src={url}
          allowtransparency="true"
          scrolling="yes"
          title={title}
          checkOrigin={false}
        />
      </Modal>
    </div>
  );
};

export default ContentModalIFrame;
