import React from "react";
import { InfoLink } from "./InfoLink";

import * as helper from "../../helper";
import { TransferModal } from "./TransferModal";

function Additional({ additionals, portalImage, evaService = false }) {
  return (
    <>
      {additionals.map((additional) => (
        <div key={additional.id} className="OfferDetail__sub-section">
          <div className="mb-4">
            <img
              className="d-block mb-3 additionals-img"
              src={additional.image ?? portalImage ?? ""}
              alt=""
            />
            <div className="row pl-2 mb-2">
              <h3
                className="h4"
                dangerouslySetInnerHTML={{
                  __html: helper.decodeHTMLEntities(additional.name),
                }}
              ></h3>
            </div>
            <div className="row pl-2 w-75 justify-content-between">
              <div className="align-self-end">
                <div
                  dangerouslySetInnerHTML={{
                    __html: helper.decodeHTMLEntities(additional.description),
                  }}
                />
                {!evaService && additionals && additionals.length > 0 && (
                  <>
                    <InfoLink links={additionals} />
                    <br />
                  </>
                )}
                <h5 className="mb-0">
                  {helper.convertISOCurrencyToSymbol(additional.price)}
                </h5>
                <p>{evaService ? "Gesamtpreis" : "Aufpreis"}</p>
              </div>
            </div>
            <br />
            {additional.extendedDescription && (
              <TransferModal
                modalTitleText={additional.name}
                modalBodyText={additional.extendedDescription}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default React.memo(Additional);
