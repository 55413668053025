import React from "react";

import OfferCardHeaderWithNav from "./OfferCardHeaderWithNav";
import TeaserImage from "./TeaserImage";
import Tag from "./Tag";
import Voting from "./Voting";

import OfferType from "../common/OfferType";
import OfferBreadcrumb from "../common/OfferBreadcrumb";
import VotingShow from "../common/VotingShow";
import ViewedShow from "../common/ViewedShow";
import { InfoLink } from "./InfoLink";

import * as helper from "../../helper";

const OfferCardRentalService = ({
  offer,
  votingState,
  hasImage,
  vote,
  hasVotingButton,
  renderButton,
  viewed,
  displayPrices,
  changeOffer,
  headerWithNav = false,
  detail = false,
}) => {
  //#region Props

  const offerType = offer.bookingTypeLabel
    ? offer.bookingTypeLabel
    : offer.type;
  const teaserImage =
    offer.rentalService.teaserImage && offer.rentalService.teaserImage.src;

  const obligatoryPricingRules = offer.services.filter(
    (x) =>
      helper.serviceFeeGuids.includes(x.guid.toUpperCase()) &&
      x.isObligatory === true
  );

  const currency = helper.getCurrency(offer.totalPrice);
  const basicTravelPrice = helper.removeAllNonNumeric(offer.totalPrice);
  let totalPrice = helper.removeAllNonNumeric(offer.totalPrice);

  obligatoryPricingRules.forEach(
    (x) => (totalPrice += parseInt(helper.removeAllNonNumeric(x.price)))
  );

  const linkData = offer.services.filter(
    (x) =>
      helper.serviceFeeGuids.includes(x.guid.toUpperCase()) &&
      x.isObligatory === true
  );

  const getDurationText = (offer) => {
    const duration = offer.rentalService.duration;
    let durationUnit = offer.rentalService.durationUnit;

    if (duration <= 0) return;

    let durationUnitText = "UNDEFINED";

    if (durationUnit === 0)
      durationUnit = helper.getDurationUnitByTravelBookingType(
        offer.travelBookingType
      );

    if (durationUnit === 1) durationUnitText = duration > 1 ? "Tage" : "Tag";
    else if (durationUnit === 2)
      durationUnitText = duration > 1 ? "Nächte" : "Nacht";

    return `${duration} ${durationUnitText}`;
  };

  const getDepartureFlightText = (offer) => {
    if (offer.flights.length > 0 && offer.flights[0].departureAirport !== null)
      return `Flug ab ${offer.flights[0].departureAirport.name}`;
  };

  const getTravelPriceView = (offer) => {
    return (
      <>
        <hr />
        <div className="d-flex justify-content-between mt-3">
          <div>Reisepreis</div>
          <div>{helper.currencyFormat(basicTravelPrice) + " " + currency}</div>
        </div>
      </>
    );
  };

  const getChildsView = (participatingChilds) => {
    let plural = "";

    if (participatingChilds >= 2) {
      plural = "er";
    }

    return `, ${participatingChilds} Kind${plural}`;
  };

  const getTotalPriceView = (offer) => {
    return (
      <>
        <hr />
        <div className="d-flex justify-content-between">
          <div>
            <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
              Gesamtpreis:
            </div>
            {offer.participatingAdults > 0 && (
              <p className={displayPrices ? "mb-1" : "mb-3"}>
                {`${offer.participatingAdults} Erwachsene`}
                {offer.participatingAdults === 1 && "r"}
                {offer.participatingChilds > 0 &&
                  getChildsView(offer.participatingChilds)}
              </p>
            )}
          </div>
          <div
            className="d-flex align-items-end"
            style={{ fontWeight: "bold", fontSize: "0.9rem" }}
          >
            {helper.currencyFormat(totalPrice) + " " + currency}
          </div>
        </div>
      </>
    );
  };

  //#endregion

  return (
    <>
      <div className="Offer">
        <div
          className={`card ${
            votingState === "thumbs-down" ? "Offer--negative" : ""
          }`}
        >
          {headerWithNav ? (
            <OfferCardHeaderWithNav offer={offer} changeOffer={changeOffer} />
          ) : (
            <div className="card-body">
              <h3 className="h3 m-0">
                Angebot Nr: {offer.number}
                <span className="font-weight-normal">
                  {" "}
                  / <OfferType>{offerType}</OfferType>
                </span>
              </h3>
            </div>
          )}
          {hasImage && (
            <TeaserImage key={teaserImage} src={teaserImage}>
              {offer.highlighted && <Tag>Mein Tip</Tag>}
              {offer.teaserAttribute && <Tag>{offer.teaserAttribute.name}</Tag>}
            </TeaserImage>
          )}

          <div className="card-body pb-4">
            <div className="Offer__infos">
              <p className="mb-1">
                <OfferBreadcrumb
                  country={offer.rentalService.country}
                  region={offer.rentalService.region}
                  city={offer.rentalService.city}
                />
              </p>
              <h2 className="card-title">{offer.rentalService.name}</h2>

              <hr />

              <ul
                className="list-unstyled mb-0"
                style={{ paddingBottom: "1rem" }}
              >
                <li>{offer.travelTime}</li>
                <li>{getDurationText(offer)}</li>
                <li>{getDepartureFlightText(offer)}</li>
              </ul>

              {!detail && (
                <>
                  {offer.rentalService.tileText && (
                    <div
                      className="Offer__rental-car-teaser-tile"
                      dangerouslySetInnerHTML={{
                        __html: offer.rentalService.tileText,
                      }}
                    />
                  )}
                  {!offer.rentalService.tileText &&
                    offer.rentalService.rate.rateServices && (
                      <>
                        <b>Leistungen</b>
                        <ul className="main-list mb-0 Offer__rental-car-teaser-tile">
                          {offer.rentalService.rate.rateServices.map(
                            (rateService, index) => (
                              <li
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: rateService,
                                }}
                              />
                            )
                          )}
                        </ul>
                      </>
                    )}
                </>
              )}

              {!offer.logoHide && offer.operatorImageUrl && (
                <div
                  className={`Offer__operator-img-wrapper${
                    detail ? "-detail" : ""
                  }`}
                >
                  <img
                    className="operator-img"
                    src={offer.operatorImageUrl}
                    alt="tour-operator-logo"
                  />
                </div>
              )}
            </div>

            {displayPrices && (
              <>
                {detail && getTravelPriceView(offer)}
                {detail && linkData && linkData.length > 0 && (
                  <InfoLink links={linkData} />
                )}

                {getTotalPriceView(offer)}
              </>
            )}

            {renderButton && <div className="Offer__link btn">Details</div>}
          </div>

          {hasVotingButton && (
            <div className="card-footer">
              <Voting vote={vote} offerId={offer.id} status={votingState} />
            </div>
          )}
        </div>

        <VotingShow votingState={votingState} />
        <ViewedShow
          viewed={viewed && (!votingState || votingState === "neutral")}
        />
      </div>
    </>
  );
};

export default React.memo(OfferCardRentalService);
