import MapMarker from "../../assets/img/map-marker.svg";

const GoogleCookieOverlay = ({ setEnabled }) => {
  return (
    <div className="general-cookie-banner gm-cookie-banner">
      <div className="general-cookie-banner__inner">
        <div className="general-cookie-banner__text">
          <div onClick={() => setEnabled(true)} style={{ cursor: "pointer" }}>
            <img src={MapMarker} alt="map-marker" />
            <h3>Bitte hier klicken, um Karte zu laden</h3>
          </div>
          <p>
            <span>
              Bei der Anzeige bzw. Verwendung von Google Maps werden Cookies
              gesetzt. Es gelten die{" "}
            </span>
            <a
              href="https://policies.google.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzbestimmungen
            </a>
            <span> von Google</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GoogleCookieOverlay;
