import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import * as helper from "../../helper";

const InfoLinkTypes = {
  TYPE_NONE: "NONE",
  TYPE_IMAGE: "IMAGE",
  TYPE_URL: "URL",
  TYPE_HTMLTEXT: "HTMLTEXT",
};

export const InfoLink = ({ links }) => {
  const hasNewInfoLinks =
    links &&
    links.length > 0 &&
    links[0].infoLinks &&
    links[0].infoLinks.length > 0;

  const [modalBodyClass, setModalBodyClass] = useState("");
  const [linkModal, setLinkModal] = useState(false);
  const [linkModalBody, setLinkModalBody] = useState();

  const currency = helper.getCurrency(links[0].price);

  const toggleLinkModal = () => setLinkModal(!linkModal);

  const onLinkClick = (image, description, infoLinks) => {
    if (infoLinks && infoLinks.length) {
      if (infoLinks[0].type === InfoLinkTypes["TYPE_HTMLTEXT"]) {
        setLinkModalBody(
          <span
            dangerouslySetInnerHTML={{
              __html: helper.decodeHTMLEntities(infoLinks[0].htmlText),
            }}
          ></span>
        );
      } else if (infoLinks[0].type === InfoLinkTypes["TYPE_IMAGE"]) {
        setLinkModalBody(
          <img
            className="img-fluid"
            style={{ maxHeight: "calc(100vh - 145px)" }}
            src={infoLinks[0].image}
            alt=""
          />
        );
        setModalBodyClass("modal-image");
      }
    } else if (image) {
      setLinkModalBody(
        <img
          className="img-fluid"
          style={{ maxHeight: "calc(100vh - 145px)" }}
          src={image}
          alt=""
        />
      );
      setModalBodyClass("modal-image");
    } else {
      setLinkModalBody(<p style={{ fontSize: "11pt" }}>{description}</p>);
    }

    toggleLinkModal();
  };

  const getLinkTitleTooltip = (x) => {
    const title = x.isObligatory
      ? x.infoLinks[0].text
        ? x.infoLinks[0].text
        : "Klick für weitere Informationen"
      : x.name;
    return title;
  };

  const getLinkDisplayText = (x) => {
    return x.isObligatory
      ? x.name
      : x.infoLinks[0].text
      ? x.infoLinks[0].text
      : "Klick für weitere Informationen";
  };

  const getLinkClass = (x) => {
    if (x.isObligatory) return "OfferDetail__link-pointer";
    else return "OfferDetail__link-pointer-underscore";
  };

  const getLinkView = () =>
    links.map((x) => (
      <div key={x.id} className="d-flex justify-content-between">
        {!hasNewInfoLinks && (
          <div
            className={getLinkClass(x)}
            onClick={() => onLinkClick(x.infoUrl, x.description, x.infoLinks)}
          >
            {x.isObligatory && (
              <>
                <span title="Klick für weitere Informationen">{x.name}</span>
                <span style={{ fontSize: "11pt" }}> 🛈</span>
              </>
            )}
          </div>
        )}
        {hasNewInfoLinks &&
          (x.infoLinks[0].type.toUpperCase() ===
            InfoLinkTypes["TYPE_HTMLTEXT"] ||
            x.infoLinks[0].type.toUpperCase() ===
              InfoLinkTypes["TYPE_IMAGE"]) && (
            <div
              className={getLinkClass(x)}
              onClick={() => onLinkClick(x.image, x.description, x.infoLinks)}
            >
              <span
                title={getLinkTitleTooltip(x)}
                dangerouslySetInnerHTML={{
                  __html: helper.decodeHTMLEntities(getLinkDisplayText(x)),
                }}
              ></span>
              {x.isObligatory && <span style={{ fontSize: "11pt" }}> 🛈</span>}
            </div>
          )}
        {hasNewInfoLinks &&
          x.infoLinks[0].type.toUpperCase() === InfoLinkTypes["TYPE_URL"] && (
            <div style={{ cursor: "pointer" }}>
              <a href={x.infoLinks[0].url} target="_blank" rel="noreferrer">
                <span
                  title={getLinkTitleTooltip(x)}
                  dangerouslySetInnerHTML={{
                    __html: helper.decodeHTMLEntities(getLinkDisplayText(x)),
                  }}
                ></span>
                {x.isObligatory && <span style={{ fontSize: "11pt" }}> 🛈</span>}
              </a>
            </div>
          )}
        {hasNewInfoLinks &&
          x.infoLinks[0].type.toUpperCase() === InfoLinkTypes["TYPE_NONE"] && (
            <div>
              {x.isObligatory && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: helper.decodeHTMLEntities(x.name),
                  }}
                ></span>
              )}
            </div>
          )}
        {x.isObligatory && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {helper.removeAllNonNumeric(x.price) + " " + currency}
          </div>
        )}
      </div>
    ));

  return (
    <>
      <Modal
        modalClassName="Offer-Modal"
        isOpen={linkModal}
        toggle={toggleLinkModal}
      >
        <ModalHeader toggle={toggleLinkModal} />
        <ModalBody className={modalBodyClass}>{linkModalBody}</ModalBody>
      </Modal>
      {getLinkView()}
    </>
  );
};
