import React from 'react';
import { ReactComponent as IconNegative } from '../../assets/img/dislike.svg';
import { ReactComponent as IconPositive } from '../../assets/img/like.svg';

class Voting extends React.Component {
  onClick = (nextStatus) => {
    const { status, vote, offerId } = this.props;

    if (status === nextStatus) {
      vote(offerId, 'neutral');

      return;
    }

    vote(offerId, nextStatus);
  };

  render() {
    const { status } = this.props;

    return (
      <div className="Voting">
        <button
          onClick={() => this.onClick('thumbs-down')}
          type="button"
          className={`Voting__btn ${status === 'thumbs-down' ? 'text-theme' : 'text-secondary'}`}
        >
          <IconNegative />
        </button>
        <button
          onClick={() => this.onClick('thumbs-up')}
          type="button"
          className={`Voting__btn ${status === 'thumbs-up' ? 'text-theme' : 'text-secondary'}`}
        >
          <IconPositive />
        </button>
      </div>
    );
  }
}

export default Voting;
