import React from 'react';
import { ReactComponent as Close } from '../../assets/img/times-light.svg';

import { ReactComponent as Square } from '../../assets/img/expand-wide-regular.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/img/chevron-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/img/chevron-right.svg';

class GalleryControl extends React.PureComponent {
  onOpen = (event) => {
    event.preventDefault();

    const { onNavigateGallery } = this.props;

    onNavigateGallery(0);
  };

  onNext = () => {
    const { imageIndex, imagesLength, onNavigateGallery } = this.props;
    let nextImageIndex = imageIndex + 1;

    if (nextImageIndex >= imagesLength) {
      nextImageIndex = 0;
    }

    onNavigateGallery(nextImageIndex);
  };

  onPrev = () => {
    const { imageIndex, imagesLength, onNavigateGallery } = this.props;
    let nextImageIndex = imageIndex - 1;

    if (nextImageIndex < 0) {
      nextImageIndex = imagesLength - 1;
    }

    onNavigateGallery(nextImageIndex);
  };

  render() {
    const { imageIndex, imagesLength, onCloseGallery } = this.props;

    if (imageIndex === null) {
      return (
        <div
          role="button"
          tabIndex={0}
          className="GalleryControl"
          onClick={this.onOpen}
          onKeyDown={this.onOpen}
        >
          <button
            type="button"
            className="GalleryControl__btn GalleryControl__btn-center"
          >
            <Square height={18} width={18} /> In Galeriemodus wechseln
          </button>
        </div>
      );
    }

    return (
      <React.Fragment>
        <button
          type="button"
          className="btn-unstyled GalleryControl__btn GalleryControl__btn-close"
          onClick={onCloseGallery}
        >
          <Close height={18} width={18} />
        </button>
        <div className="GalleryControl">
          <button type="button" className="GalleryControl__btn GalleryControl__btn-arrow" onClick={this.onPrev}>
            <ArrowLeftIcon height={16} width={16} />
          </button>
          <button type="button" className="GalleryControl__btn">
            Bild {imageIndex + 1} / {imagesLength}
          </button>
          <button type="button" className="GalleryControl__btn GalleryControl__btn-arrow" onClick={this.onNext}>
            <ArrowRightIcon height={16} width={16} />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default GalleryControl;
