function OfferType({ children }) {
  const map = {
    HotelOnly: 'Nur Hotel',
    HolidayHome: 'Ferienhaus',
    Cruise: 'Kreuzfahrt',
    Tour: 'Rundreise',
    CruiseOnly: 'Nur Kreuzfahrt',
    Charter: 'Nur Flug ',
    Flat: 'Pauschal',
  };

  return map[children] || children || 'unknown';
}

export default OfferType;
