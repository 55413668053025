import React from 'react';
import ImageFallback from '../common/ImageFallback';

function TeaserImage({ children, src, additionalClass }) {
  return (
    <ImageFallback image={src}>
      {image => (
        <div
          className={`Offer__teaser-img ${(additionalClass ? additionalClass : '')}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          {children}
        </div>
      )}
    </ImageFallback>
  );
}

export default TeaserImage;
