import env from "./env";

const REACT_APP_PAX_CENTRAL_URL = env("REACT_APP_PAX_CENTRAL_URL");
const REACT_APP_X_API_TOKEN = env("REACT_APP_X_API_TOKEN");

class Api {
  async startSession(sessionId, data, token) {
    await this.post(
      `/v1/SmartBeratung/Session/${sessionId}`,
      {
        ...data,
        token,
      },
      token
    );
  }

  async postSBInvitation(sessionId, token) {
    await this.post(`/v1/SmartBeratung/Session/${sessionId}/Invite`, {}, token);
  }

  async patchSession(sessionId, data, token) {
    await this.patch(`/v1/SmartBeratung/Session/${sessionId}`, data, token);
  }

  async stopSession(sessionId, token) {
    await this.delete(`/v1/SmartBeratung/Session/${sessionId}`, token);
  }

  async getSession(sessionId, token) {
    return this.get(`/v1/SmartBeratung/Session/${sessionId}`, token);
  }

  async getAgencyId(advisoryId, token) {
    const data = await this.get(
      `/v1/SmartBeratung/Advisory/${advisoryId}/Agency`,
      token
    );

    return data.id;
  }

  async getAgencyData(agencyId, token) {
    return this.get(`/v1/SmartBeratung/Agency/${agencyId}`, token);
  }

  async getOverviewData(overviewId) {
    return this.get(`/v1/SmartBeratung/Info/AgencyOverview/${overviewId}`);
  }

  async getAdvisory(advisoryId, token) {
    return this.get(`/v1/SmartBeratung/Advisory/${advisoryId}`, token);
  }

  async getTrustYouInfo(id, token) {
    return this.get(`/v1/TrustYou/Hotel/${id}`, token);
  }

  async getTrustYouRating(id, token) {
    return this.get(`/v1/TrustYou/Hotel/${id}/Rating`, token);
  }

  async getHolidayCheckInfo(id, token) {
    return this.get(`/v1/HolidayCheck/Hotel/${id}`, token);
  }

  async getHolidayCheckRating(id, token) {
    return this.get(`/v1/HolidayCheck/Hotel/${id}/Rating`, token);
  }

  // eslint-disable-next-line class-methods-use-this
  async getXApiContent(url) {
    const headers = {
      "Content-Type": "text/html",
      Accept: "text/html",
      "X-API-KEY": REACT_APP_X_API_TOKEN,
    };

    const response = await fetch(`${REACT_APP_PAX_CENTRAL_URL}${url}`, {
      headers,
    });

    if (response.status !== 200) {
      await throwError(response);
    }

    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  async get(url, token) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${REACT_APP_PAX_CENTRAL_URL}${url}`, {
      headers,
    });

    if (response.status !== 200) {
      await throwError(response);
    }

    return response.json();
  }

  // eslint-disable-next-line class-methods-use-this
  async post(url, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${REACT_APP_PAX_CENTRAL_URL}${url}`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      await throwError(response);
    }

    if (response.headers.get("content-type")) return response.json();
    else return;
  }

  // eslint-disable-next-line class-methods-use-this
  async patch(url, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${REACT_APP_PAX_CENTRAL_URL}${url}`, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
    });

    if (response.status !== 200) {
      await throwError(response);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async delete(url, token) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`${REACT_APP_PAX_CENTRAL_URL}${url}`, {
      method: "DELETE",
      headers,
    });

    if (response.status !== 200) {
      await throwError(response);
    }
  }
}

async function throwError(response) {
  throw new HttpError(
    response.statusText,
    response.status,
    await response.json()
  );
}

function HttpError(message, code, data) {
  this.message = message;
  this.code = code;
  this.data = data;
}

const api = new Api();

export default api;
