import React from "react";
import ImageFallback from "../common/ImageFallback";

function Impressions({ images, onNavigateGallery }) {
  return (
    <section id="impressions" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Impressionen</h1>
      </header>
      <ul className="row list-unstyled">
        {images.map((image, index) => (
          <ImageFallback key={image.src} image={image.src}>
            {(src) => (
              <li className="col-4 mb-4">
                <button
                  onClick={() => onNavigateGallery(index)}
                  type="button"
                  className="btn-unstyled"
                >
                  <img src={src} className="img-fluid" alt="" />
                </button>
              </li>
            )}
          </ImageFallback>
        ))}
      </ul>
    </section>
  );
}

export default Impressions;
