import React from 'react';

function Attribute({ name }) {
  return (
    <div className="OfferDetail__attribute">
      {name}
    </div>
  );
}

function Attributes({ offer }) {
  const attributes = offer.attributes || [];

  return (
    <section id="attributes" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Info zum Angebot</h1>
      </header>
      <div className="OfferDetail__attribute-container">
        {attributes.map(attribute => <Attribute key={attribute.id} name={attribute.name} />)}
      </div>
    </section>
  );
}

export default Attributes;
