import React from 'react';
import QRCodeGenerator from 'qrcode';

class QRCode extends React.PureComponent {

  state = {
    scale: 1,
    svg: ''
  };

  componentDidMount() {
    this.generateQrCode();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.generateQrCode();
    }
  }

  async generateQrCode() {
    const { value } = this.props;

    const svg = await QRCodeGenerator.toString(value, {
      type: 'svg',
      version: 4,
      mode: 'alphanumeric',
      errorCorrectionLevel: 'L'
    });

    this.setState({
      svg: svg
    });
  }

  toggleScale = () => {
    this.setState({
      scale: this.state.scale === 1 ? 3 : 1,
    });
  };

  render() {
    const { scale, svg } = this.state;
    return (
        <div
          onClick={this.toggleScale}
          className="qr-code"
          style={{ transform: `scale(${scale})` }}
          dangerouslySetInnerHTML={{__html: svg}}
        />
    );
  }

}

export default QRCode;
