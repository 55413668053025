import React from 'react';
import AgentImage from './AgentImage';
import QRCode from './QRCode';

function InfoBar({
  agent, advisoryId, portalImage, companyName, preview
}) {

  const url = new URL(document.location.href);
  const params = new URLSearchParams(url.searchParams);
  params.delete('ref');
  url.search = params.toString();

  return (
    <div className="InfoBar">
      <div className="InfoBar__first-line container-fluid px-3 py-1">
        <b>Beratung {advisoryId}</b>
      </div>
      <div className="d-flex align-items-center justify-content-between container-fluid px-3 py-2">
        <div className="d-flex align-items-center">
          <AgentImage path={agent.image} />
          <div className="InfoBar__consultant-info">
            <h5 className="mb-0">{agent.namePrefix} {agent.firstName} {agent.lastName}</h5>
            <p className="mb-0">{companyName}</p>
          </div>
        </div>
        <div className="d-flex">
          {preview && (
            <QRCode value={url.toString()} />
          )}
          {portalImage && (
            <img className="InfoBar__portal-logo" src={portalImage} alt="" />
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoBar;
