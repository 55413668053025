import React from 'react';
import Peer from 'simple-peer';
import env from '../../env';

import { ReactComponent as PhoneSlashIcon } from '../../assets/img/phone-slash-solid.svg';
import { ReactComponent as VideoIcon } from '../../assets/img/video-solid.svg';
import { ReactComponent as VideoSlashIcon } from '../../assets/img/video-slash-solid.svg';
import { ReactComponent as MicrophoneIcon } from '../../assets/img/microphone-solid.svg';
import { ReactComponent as MicrophoneSlashIcon } from '../../assets/img/microphone-slash-solid.svg';
import { ReactComponent as SettingsIcon } from '../../assets/img/cog-solid.svg';

const STATUS_WAITING = 'WAITING';
const STATUS_INIT = 'INIT';
const STATUS_CONNECTED = 'CONNECTED';
const STATUS_ERROR = 'ERROR';

function setStream(stream, el) {
  if ('srcObject' in el) {
    // eslint-disable-next-line no-param-reassign
    el.srcObject = stream;
  } else {
    // eslint-disable-next-line no-param-reassign
    el.src = window.URL.createObjectURL(stream);
  }

  el.play();
}

class Video extends React.PureComponent {
  state = {
    status: STATUS_WAITING,
    video: false,
    audio: true,
    showSettings: false,
    otherVideo: true,
    otherAudio: true,
    audioInputDeviceId: localStorage.getItem('audioInputDeviceId') || '',
    videoInputDeviceId: localStorage.getItem('videoInputDeviceId') || ''
  };

  elOther = null;

  /**
   * @type {MediaStream}
   */
  otherStream = null;

  elYou = null;

  /**
   * @type {MediaStream}
   */
  stream = null;

  /**
   * @type {MediaStreamTrack}
   */
  videoTrack = null;

  /**
   * @type {MediaStreamTrack}
   */
  audioTrack = null;

  /**
   * @type {Peer}
   */
  peer = null;

  waitPermissionForVideo = false;

  waitPermissionForAudio = false;

  async componentDidMount() {
    const { signal, initiator = false } = this.props;
    const { video, audio, videoInputDeviceId, audioInputDeviceId } = this.state;

    signal.on('ReceiveCallCommand', this.onCallCommand);

    if (video) {
      this.setVideoStream(videoInputDeviceId);
    }

    if (audio) {
      this.setAudioStream(audioInputDeviceId);
    }

    if (!initiator) {
      this.connect();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { showFullscreenVideo } = this.props;
    const { video, audio, videoInputDeviceId, audioInputDeviceId } = this.state;

    if (prevState.video === false && video === true) {
      this.setVideoStream(videoInputDeviceId);
    }

    if (prevState.video === true && video === false) {
      this.stopVideo();
    }

    if (prevState.audio === false && audio === true) {
      this.setAudioStream(audioInputDeviceId);
    }

    if (prevState.audio === true && audio === false) {
      this.stopAudio();
    }

    if (prevProps.showFullscreenVideo !== showFullscreenVideo)
      this.setFullscreenElements(showFullscreenVideo);

    localStorage.setItem('audioInputDeviceId', audioInputDeviceId);
    localStorage.setItem('videoInputDeviceId', videoInputDeviceId);
  }

  componentWillUnmount() {
    const { signal } = this.props;

    signal.off('ReceiveCallCommand', this.onCallCommand);

    this.disconnect();

    if (this.videoTrack) {
      this.videoTrack.stop();
    }

    if (this.audioTrack) {
      this.audioTrack.stop();
    }
  }

  setFullscreenElements = (isFullscreen) => {
    if (isFullscreen) {
      this.elOther.style.cssText = 'position: relative; width: 100%; maxHeight: 100vh; top: 50%; transform: translateY(-50%);';
    } else {
      this.elOther.style.cssText = 'width: 100%; max-height: 152px';
    }
  };

  onCallCommand = (message) => {
    const data = JSON.parse(message);

    if (data.command === 'Handshake') {
      this.peer.signal(JSON.parse(data.data));
    }

    if (data.command === 'AcceptCall') {
      this.connect();
    }
  };

  connect = async () => {
    if (this.peer) {
      return;
    }

    this.setState({
      status: STATUS_INIT,
    });

    const { initiator = false, signal } = this.props;

    this.peer = new Peer({
      initiator,
      config: {
        iceServers: [
          {
            urls: env('REACT_APP_STUN_URLS').split(','),
          },
          {
            urls: env('REACT_APP_TURN_URLS').split(','),
            username: env('REACT_APP_TURN_USERNAME'),
            credential: env('REACT_APP_TURN_CREDENCIAL'),
          },
        ],
      },
    });

    this.peer.on('signal', (peerData) => {
      signal.invoke('SendCallCommandAsync', JSON.stringify(
        {
          command: 'Handshake',
          data: JSON.stringify(peerData),
        },
      ));
    });

    this.peer.on('connect', () => {
      this.setState({
        status: STATUS_CONNECTED,
      });

      this.sendInfo();
    });

    this.peer.on('stream', (stream) => {
      this.otherStream = stream;
      setStream(stream, this.elOther);
    });

    this.peer.on('track', (track) => {
      if (this.otherStream) {
        this.otherStream.addTrack(track);
      }
    });

    this.peer.on('close', () => {
      console.log('CONNECTION CLOSE');
      // do nothing?
    });

    this.peer.on('error', (err) => {
      console.error('CONNECTION ERROR:', err);
      // do nothing?
    });

    this.peer.on('data', (data) => {
      const parsedData = JSON.parse(data);

      this.setState({
        otherVideo: parsedData.video,
        otherAudio: parsedData.audio,
      });
    });

    if (this.stream) {
      this.peer.addStream(this.stream);
    }
  };

  disconnect = () => {
    if (this.peer) {
      this.peer.destroy();
      this.peer = null;
    }
  };

  onAudio = () => {
    const { audio } = this.state;

    this.setState({
      audio: !audio,
    }, () => {
      this.sendInfo();
    });
  };

  onVideo = () => {
    const { video } = this.state;

    this.setState({
      video: !video,
    }, () => {
      this.sendInfo();
    });
  };

  sendInfo() {
    if (!this.peer) {
      return;
    }

    const { audio, video } = this.state;

    this.peer.send(JSON.stringify({
      audio,
      video,
    }));
  }

  setAudioInput = async (deviceId) => {
    this.setState({
      audioInputDeviceId: deviceId,
    });
    await this.setAudioStream(deviceId);
  };

  setVideoInput = async (deviceId) => {
    this.setState({
      videoInputDeviceId: deviceId,
    });
    await this.setVideoStream(deviceId);
  };

  async setAudioStream(audioInputDeviceId = null) {
    const { audio } = this.state;

    if (this.waitPermissionForAudio) {
      return;
    }

    this.waitPermissionForAudio = true;

    let stream;

    try {
      const constraints = audioInputDeviceId ?
        { audio: { deviceId: { exact: audioInputDeviceId } } } :
        { audio: true };

      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (e) {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
    }

    this.waitPermissionForAudio = false;

    // merke alten track und hole neuen
    const oldAudioTrack = this.audioTrack;
    this.audioTrack = stream.getTracks()[0];
    this.audioTrack.enabled = audio;

    // wenn kein stream existiert, dann diesen stream verwenden und tracks anhängen
    if (!this.stream) {
      this.stream = stream;

      setStream(this.stream, this.elYou);

      if (this.peer) {
        this.peer.addStream(this.stream);
      }

      return;
    }

    // alten track aus dem stream entfernen
    if (oldAudioTrack) {
      oldAudioTrack.stop();
      this.stream.removeTrack(oldAudioTrack);

      if (this.peer) {
        this.peer.replaceTrack(oldAudioTrack, this.audioTrack, this.stream);
      }

      this.stream.addTrack(this.audioTrack);
      return;
    }

    // neuen track an den stream einfügen
    this.stream.addTrack(this.audioTrack);

    if (this.peer) {
      this.peer.addTrack(this.audioTrack, this.stream);
    }
  }

  async stopAudio() {
    if (!this.audioTrack) {
      return;
    }

    this.audioTrack.enabled = false;
  }

  async setVideoStream(videoInputDeviceId = null) {
    const { video } = this.state;

    if (this.waitPermissionForVideo) {
      return;
    }

    this.waitPermissionForVideo = true;

    let stream;
    try {
      const constraints = videoInputDeviceId ?
        {
          video: {
            deviceId: { exact: videoInputDeviceId },
          },
        } :
        {
          video: {
            facingMode: 'user',
            width: { ideal: 1280 },
            height: { ideal: 720 },
          },
        };

      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (e) {
      stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user',
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });
    }

    this.waitPermissionForVideo = false;

    // merke alten track und hole neuen
    const oldVideoTrack = this.videoTrack;
    this.videoTrack = stream.getTracks()[0];
    this.videoTrack.enabled = video;

    // wenn kein stream existiert, dann diesen stream verwenden und tracks anhängen
    if (!this.stream) {
      this.stream = stream;

      setStream(this.stream, this.elYou);

      if (this.peer) {
        this.peer.addStream(this.stream);
      }

      return;
    }

    // alten track aus dem stream entfernen
    if (oldVideoTrack) {
      oldVideoTrack.stop();
      this.stream.removeTrack(oldVideoTrack);

      if (this.peer) {
        this.peer.replaceTrack(oldVideoTrack, this.videoTrack, this.stream);
      }

      this.stream.addTrack(this.videoTrack);
      return;
    }

    // neuen track an den stream einfügen
    this.stream.addTrack(this.videoTrack);

    if (this.peer) {
      this.peer.addTrack(this.videoTrack, this.stream);
    }
  }

  async stopVideo() {
    if (!this.videoTrack) {
      return;
    }

    this.videoTrack.enabled = false;
  }

  toggleSettings = () => {
    this.setState({
      showSettings: !this.state.showSettings,
    });
  };

  renderStatus() {
    const { status } = this.state;

    let text = null;

    if (status === STATUS_WAITING) {
      text = 'Klingelt';
    }

    if (status === STATUS_INIT) {
      text = 'Verbindung wird aufgebaut';
    }

    if (status === STATUS_ERROR) {
      text = 'Ein Fehler ist aufgetreten';
    }

    if (!text) {
      return null;
    }

    return (
      <div
        style={{
          color: '#ffffff',
          lineHeight: '5em',
          textAlign: 'center',
          position: 'absolute',
          width: '100%',
        }}
      >
        {text}
      </div>
    );
  }

  render() {
    const {
      video, audio, otherAudio, otherVideo, videoInputDeviceId, audioInputDeviceId, showSettings
    } = this.state;

    const { initiator = false, onClose, showFullscreenVideo } = this.props;

    return (
      <div
        style={{
          position: !initiator && showFullscreenVideo ? 'fixed' : 'relative',
          width: '100%',
          height: !initiator && showFullscreenVideo ? '100%' : 'auto',
          background: '#000000',
        }}
      >
        {this.renderStatus()}
        <Overlay
          audio={otherAudio}
          video={otherVideo}
        />
        <YouVideo
          active={video}
          initiator={initiator}
          showFullscreenVideo={showFullscreenVideo}
          videoRef={(el) => {
            this.elYou = el;
          }}
        />
        <OtherVideo
          videoRef={(el) => {
            this.elOther = el;
          }}
        />
        <Buttons
          initiator={initiator}
          showFullscreenVideo={showFullscreenVideo}
          video={video}
          audio={audio}
          onVideo={this.onVideo}
          onAudio={this.onAudio}
          onClose={onClose}
        />
        {showSettings && (
          <Settings
            audioInputDeviceId={audioInputDeviceId}
            videoInputDeviceId={videoInputDeviceId}
            onSelectAudioInput={this.setAudioInput}
            onSelectVideoInput={this.setVideoInput}
          />
        )}
        <h3 onClick={this.toggleSettings} className={`VideoSettings__header ${showSettings ? 'VideoSettings__header--active' : ''}`}>
          <SettingsIcon />
        </h3>
      </div>
    );
  }
}

class Buttons extends React.PureComponent {
  render() {
    const {
      audio,
      video,
      onAudio,
      onVideo,
      onClose,
      initiator,
      showFullscreenVideo
    } = this.props;

    return (
      <div className={`${!initiator && showFullscreenVideo ? 'VideoButtonContainer VideoButtonContainer__Fullscreen' : 'VideoButtonContainer'}`}>
        <button className="VideoButton" type="button" onClick={onAudio}>
          {audio ? <MicrophoneIcon height={18} width={18} /> : <MicrophoneSlashIcon height={18} width={18} />}
        </button>
        <button className="VideoButton" type="button" onClick={onVideo}>
          {video ? <VideoIcon height={18} width={18} /> : <VideoSlashIcon height={18} width={18} />}
        </button>
        <button className="VideoButton danger" type="button" onClick={onClose}>
          <PhoneSlashIcon height={18} width={18} />
        </button>
      </div>
    );
  }
}

class Overlay extends React.PureComponent {
  render() {
    const { audio, video } = this.props;

    if (audio && video) {
      return null;
    }

    return (
      <div className="VideoOverlay">
        {!audio && <div className="VideoOverlay--item"><MicrophoneSlashIcon height={18} width={18} /></div>}
        {!video && <div className="VideoOverlay--item"><VideoSlashIcon height={18} width={18} /></div>}
      </div>
    );
  }
}

class YouVideo extends React.PureComponent {
  render() {
    const { videoRef, active, initiator, showFullscreenVideo } = this.props;

    return (
      <video
        muted
        ref={videoRef}
        style={{
          position: 'absolute',
          bottom: !initiator && showFullscreenVideo ? '12px' : '45px',
          right: !initiator && showFullscreenVideo ? '12px' : '5px',
          width: '20%',
          transform: 'rotateY(180deg) translate3d(0, 0, -1px)',
          display: active ? 'block' : 'none',
          zIndex: 50
        }}
      />
    );
  }
}

class OtherVideo extends React.PureComponent {
  render() {
    const { videoRef } = this.props;

    return (
      <video
        ref={videoRef}
        style={{
          width: '100%',
          maxHeight: '152px'
        }}
      />
    );
  }
}

class Settings extends React.PureComponent {
  state = {
    audioInputs: [],
    videoInputs: [],
  };

  async componentDidMount(prevProps, prevState, snapshot) {
    this.setState({
      audioInputs: await this.getDevices('audioinput'),
      videoInputs: await this.getDevices('videoinput'),
    });
  }

  async getDevices(kind) {
    const devices = await navigator.mediaDevices.enumerateDevices();

    return devices
      .filter(device => device.kind === kind && device.deviceId.length > 0 && device.label.length > 0)
      .map(device => ({
        deviceId: device.deviceId,
        label: device.label,
      }));
  }

  render() {
    const { audioInputDeviceId, videoInputDeviceId, onSelectAudioInput, onSelectVideoInput } = this.props;
    const { audioInputs, videoInputs } = this.state;

    return (
      <div className="VideoSettings">
        <div className="VideoSettings__modal">
          <h4>Audio</h4>
          <DeviceDropdown
            name="audio"
            icon={<MicrophoneIcon />}
            deviceId={audioInputDeviceId}
            devices={audioInputs}
            onChange={onSelectAudioInput}
          />
          <h4>Kamera</h4>
          <DeviceDropdown
            name="videos"
            icon={<VideoIcon />}
            deviceId={videoInputDeviceId}
            devices={videoInputs}
            onChange={onSelectVideoInput}
          />
        </div>
      </div>
    );
  }
}

class DeviceDropdown extends React.PureComponent {
  render() {
    const { deviceId, devices, icon, name, onChange } = this.props;

    if (devices.length === 0) {
      return (
        <div className="VideoSettings__row">
          <label htmlFor={name + '-select'} className="VideoSettings__label">
            <span className={`VideoSettings__icon-${name}`}>{icon}</span>
          </label>
          <span className="VideoSettings__select">Kein Geräte gefunden.</span>
        </div>
      );
    }

    return (
      <div className="VideoSettings__row">
        <label htmlFor={name + '-select'} className="VideoSettings__label">
          <span className={`VideoSettings__icon-${name}`}>{icon}</span>
        </label>
        <select
          id={name + '-select'}
          className="VideoSettings__select"
          value={deviceId}
          onChange={(event) => onChange(event.target.value)}
        >
          <option value="">Browser Standard</option>
          {devices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default Video;
