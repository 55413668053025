import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Login from "../components/smartApp/Login";
import Session from "../components/smartApp/Session";
import WelcomeScreensWrapper from "../components/smartApp/WelcomeScreensWrapper";
import "moment/locale/de";
import AgencyOverview from "../components/smartApp/AgencyOverview";
import AgencyOverviewChannel from "../components/smartApp/AgencyOverviewChannel";

class SmartApp extends React.Component {
  componentDidMount() {
    const { history } = this.props;

    if (history.location.pathname !== "/") {
      return;
    }

    const sessionKey = localStorage.getItem("sessionKey");

    if (sessionKey !== null) {
      history.push(`/${sessionKey}`);
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/" exact>
          <WelcomeScreensWrapper>
            <h1 className="h1 mb-3">
              Willkommen zu Ihrer
              <br />
              smart-Beratung
            </h1>
            <hr />
            <Login />
          </WelcomeScreensWrapper>
        </Route>
        <Route path="/overview/:agencyOverviewId" exact>
          {(props) => (
            <AgencyOverview
              key={props.match.params.agencyOverviewId}
              {...props}
            />
          )}
        </Route>
        <Route path="/overview/:agencyOverviewId/:pos" exact>
          {(props) => (
            <AgencyOverviewChannel key={props.match.params.pos} {...props} />
          )}
        </Route>
        <Route path="/:sessionKey">
          {(props) => (
            <Session key={props.match.params.sessionKey} {...props} />
          )}
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
}

export default withRouter(SmartApp);
