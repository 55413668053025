import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import history from "./history";
import env from "./env";

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: env(
      "REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY"
    ),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});

ai.loadAppInsights();

const wrapper = (Component) =>
  withAITracking(reactPlugin, Component, null, "h-100");

export default wrapper;
export const { appInsights } = ai;
