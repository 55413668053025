import React, { useState } from "react";
import YTCookieOverlay from "../../components/smartApp/YTCookieOverlay";

const VideoTypes = {
  YT: "Youtube",
  Vimeo: "Vimeo",
  Other: "Other",
};

const YTVideo = ({ urlObj, key }) => {
  urlObj.searchParams.delete("mute");
  urlObj.searchParams.append("mute", "1");

  return (
    <iframe
      className="embed-responsive-item"
      title={key}
      src={urlObj.toString()}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

const VimeoVideo = ({ urlObj, key }) => {
  urlObj.searchParams.delete("mute");
  urlObj.searchParams.append("mute", "1");

  return (
    <iframe
      className="embed-responsive-item"
      title={key}
      src={urlObj.toString()}
      allow="autoplay; fullscreen"
      allowFullScreen
    />
  );
};

const OtherVideo = ({ urlObj, videoUrl, key }) => {
  return (
    <video muted controls title={key} className="embed-responsive-item">
      <source
        src={urlObj.toString()}
        type={videoUrl.includes("webm") ? "video/webm" : "video/mp4"}
      />
    </video>
  );
};

const Video = ({ video, key }) => {
  const urlObj = new URL(video.url);

  const getVideoType = () => {
    if (
      urlObj.hostname.includes("youtube.com") ||
      urlObj.hostname.includes("youtu.be")
    ) {
      return VideoTypes.YT;
    } else if (urlObj.hostname.includes("vimeo.com")) {
      return VideoTypes.Vimeo;
    } else {
      return VideoTypes.Other;
    }
  };

  const videoType = getVideoType(urlObj);

  switch (videoType) {
    case VideoTypes.YT:
      return <YTVideo urlObj={urlObj} key={key} />;
    case VideoTypes.Vimeo:
      return <VimeoVideo urlObj={urlObj} />;
    default:
      return <OtherVideo urlObj={urlObj} videoUrl={video.url} key={key} />;
  }
};

function Videos({ videos }) {
  const [enabled, setEnabled] = useState(false);

  const hasYTVideos = videos.find(
    (video) =>
      video.url.indexOf("youtube") !== -1 ||
      video.url.indexOf("youtu.be") !== -1
  )
    ? true
    : false;

  return (
    <section id="videos" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Videos</h1>
      </header>

      {enabled || !hasYTVideos ? (
        videos.map((video) => (
          <div
            className="Video--item embed-responsive embed-responsive-16by9"
            key={video.url}
          >
            <Video video={video} />
          </div>
        ))
      ) : (
        <YTCookieOverlay
          setEnabled={setEnabled}
          singular={videos.length === 1}
        />
      )}
    </section>
  );
}

export default Videos;
