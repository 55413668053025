export const serviceFeeGuids = [
  "3A222A9C-5676-4ECE-960C-AFA583365407",
  "3A222A9C-5676-4ECE-960C-AFA583365408",
  "3A222A9C-5676-4ECE-960C-AFA583365409",
  "77D12871-A3F6-49A9-9819-5C8DF4F20848",
  "520BA36B-9D52-4A45-92E6-92015231F091",
];

export const TranferDisplayTypes = {
  PARKING: "Parking",
  LOUNGE: "Lounge",
  HOTEL: "AirportHotel",
};

export function decodeHTMLEntities(text) {
  let textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

export function hasAttributes(offer) {
  return offer.attributes && offer.attributes.length > 0;
}

export function hasDescription(offer) {
  return !!offer.accommodation.description;
}

export function hasFlights(offer) {
  return offer.flights && offer.flights.length > 0;
}

export function hasImpressions(offer) {
  if (offer.accommodation && offer.accommodation.images)
    return offer.accommodation.images.length > 0;

  if (offer.rentalService && offer.rentalService.images)
    return offer.rentalService.images.length > 0;

  return false;
}

export function hasVideos(offer) {
  return offer.videos && offer.videos.length > 0;
}

export function hasLocation(offer) {
  return !!offer.accommodation.position;
}

export function hasRating(offer) {
  let hasRating = false;

  if (
    (offer.accommodation?.offerRatingProvider?.holidayCheckHotel &&
      offer.accommodation?.offerRatingProvider?.holidayCheckRating) ||
    offer.accommodation?.offerRatingProvider?.holidayCheckHotelV3
  )
    hasRating = true;

  if (
    offer.accommodation?.offerRatingProvider?.trustYouHotel &&
    offer.accommodation?.offerRatingProvider?.trustYouRating
  )
    hasRating = true;

  return hasRating;
}

export function hasInsurance(offer) {
  const insurances = offer.services.filter(
    (service) => service.type === "Insurance"
  );

  return insurances.length > 0;
}

export function hasServices(offer) {
  const rentalCars = offer.services.filter(
    (service) =>
      service.type === "RentalCar" && service.providerName !== "Atmosfair"
  );

  const transfers = offer.services.filter(
    (service) => service.type === "Transfer"
  );

  const additionals = offer.services.filter(
    (service) =>
      serviceFeeGuids.includes(service.guid.toUpperCase()) &&
      service.isObligatory === false
  );

  return !!(
    rentalCars.length > 0 ||
    transfers.length > 0 ||
    additionals.length > 0
  );
}

export function getCustomServices(offer) {
  const ownServices = offer.services.filter(
    (service) =>
      service.addOnServiceDisplayType.indexOf("CustomAddOnService") !== -1
  );

  return ownServices;
}

export function hasAtmosfairs(offer) {
  // const atmosfairs = offer.services.filter(
  //   (service) =>
  //     service.type !== "RentalCar" && service.providerName === "Atmosfair"
  // );

  // return atmosfairs.length > 0;

  // TODO: show Atmosfair only when and if api returns calculated values (currently always 0 €/1 €)
  return false;
}

export function sortTransferServices(transfers) {
  // Sorting: 1. Parking 2. Hotel 3. Lounges
  transfers.sort((a, b) => {
    if (a.addOnServiceDisplayType === TranferDisplayTypes.PARKING) return -1;

    if (
      a.addOnServiceDisplayType === TranferDisplayTypes.HOTEL &&
      b.addOnServiceDisplayType === TranferDisplayTypes.LOUNGE
    )
      return -1;

    if (
      a.addOnServiceDisplayType === TranferDisplayTypes.LOUNGE &&
      b.addOnServiceDisplayType === TranferDisplayTypes.HOTEL
    )
      return 1;

    return 0;
  });

  return transfers;
}

export function isRentalService(offers, id = -1) {
  let found = false;

  if (id === -1 && !Array.isArray(offers)) {
    found = !!offers.rentalService;
  } else {
    let id_find = offers.find((element) => element.id === parseInt(id));

    if (id_find) found = !!id_find.rentalService;
  }

  return found;
}

export function isCharter(offers, id = -1) {
  let found = false;

  if (id === -1 && !Array.isArray(offers)) {
    found = offers.travelBookingType === "Charter";
  } else {
    let id_find = offers.find((element) => element.id === parseInt(id));

    if (id_find) found = id_find.travelBookingType === "Charter";
  }

  return found;
}

export function hasRentalServiceVehicle(offer) {
  return !!offer.rentalService && !!offer.rentalService.vehicle;
}

export function hasRentalServiceRateOrDescription(offer) {
  return (
    offer.rentalService &&
    (!!offer.rentalService.description ||
      !!offer.rentalService.rate.rentalServices)
  );
}

export function pickUpDropOffIdentical(offer) {
  return (
    offer.rentalService &&
    offer.rentalService.pickUp.name === offer.rentalService.dropOff.name &&
    offer.rentalService.pickUp.latitude ===
      offer.rentalService.dropOff.latitude &&
    offer.rentalService.pickUp.longitude ===
      offer.rentalService.dropOff.longitude
  );
}

export function hasPickUpLocation(offer) {
  return (
    !!offer.rentalService &&
    !!offer.rentalService.pickUp.latitude &&
    !!offer.rentalService.pickUp.longitude
  );
}

export function hasDropOffLocation(offer) {
  return (
    !!offer.rentalService &&
    !!offer.rentalService.dropOff.latitude &&
    !!offer.rentalService.dropOff.longitude
  );
}

export function currencyFormat(num) {
  return num
    .toFixed(0)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

export function removeAllNonNumeric(inputString) {
  return parseInt(inputString.replace(/\D/g, ""));
}

export function getCurrency(inputString) {
  let priceWoCurrency = currencyFormat(
    parseFloat(inputString.replace(/[^0-9,-]+/g, ""))
  );

  let currencyWoPrice = inputString.replace(/[0-9,. ]/g, "");

  let convertedCurrency;

  const euroCheck = inputString.match(/eur/i);
  const chfCheck = inputString.match(/fr.|chf|fr/i);
  const usdCheck = inputString.match(/dollar|usd|\$/i);
  const gbpCheck = inputString.match(/pound|gbp/i);

  if (euroCheck && euroCheck.length) {
    convertedCurrency = "€";
  } else if (chfCheck && chfCheck.length) {
    convertedCurrency = "CHF";
  } else if (usdCheck && usdCheck.length) {
    convertedCurrency = "US$";
  } else if (gbpCheck && gbpCheck.length) {
    convertedCurrency = "£";
  }

  if (priceWoCurrency && convertedCurrency) return convertedCurrency;

  return currencyWoPrice;
}

export function convertISOCurrencyToSymbol(inputString) {
  let priceWoCurrency = currencyFormat(
    parseFloat(inputString.replace(/[^0-9,-]+/g, ""))
  );

  let currencyWoPrice = inputString.replace(/[0-9,. ]/g, "");

  let convertedCurrency;

  const euroCheck = inputString.match(/eur/i);
  const chfCheck = inputString.match(/fr.|chf|fr/i);
  const usdCheck = inputString.match(/dollar|usd|\$/i);
  const gbpCheck = inputString.match(/pound|gbp/i);

  if (euroCheck && euroCheck.length) {
    convertedCurrency = "€";
  } else if (chfCheck && chfCheck.length) {
    convertedCurrency = "CHF";
  } else if (usdCheck && usdCheck.length) {
    convertedCurrency = "US$";
  } else if (gbpCheck && gbpCheck.length) {
    convertedCurrency = "£";
  }

  if (priceWoCurrency && convertedCurrency)
    return priceWoCurrency + " " + convertedCurrency;

  return priceWoCurrency + " " + currencyWoPrice;
}

export function getDurationUnitByTravelBookingType(travelBookingType) {
  switch (travelBookingType) {
    case "carRental":
    case "tour":
    case "charter":
    case "event":
      return 1;
    case "flat":
    case "hotelOnly":
    case "holidayHome":
    case "cruise":
    case "cruiseOnly":
    case "unknown":
      return 2;
    default:
      return 2;
  }
}

export function isValidSessionKey(sessionKey) {
  const validSessionKeyExp = new RegExp(/^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/);
  return validSessionKeyExp.test(sessionKey);
}

export const getOfferIdFromPath = (path) => {
  const regEx = new RegExp(/(?<=offer\/)(.[0-9]*)/);
  const offerId = path.match(regEx);

  return offerId && offerId.length > 0 ? offerId[0] : null;
};
