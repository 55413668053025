import React from "react";
import { Tooltip as ReactStrapTooltip } from "reactstrap";

/**
 * Wrapper for ReactStrap Tooltip to avoid errors if the referenced element is not yet rendered
 * @see https://github.com/reactstrap/reactstrap/issues/773#issuecomment-627063969
 */
class Tooltip extends React.PureComponent {
  state = {
    ready: false,
  };

  componentDidMount() {
    this.setReady();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setReady();
  }

  setReady() {
    const { ready } = this.state;
    const { target } = this.props;

    if (ready) {
      return;
    }

    if (target && target.current) {
      this.setState({
        ready: true,
      });
    }
  }

  render() {
    const { ready } = this.state;

    if (!ready) {
      return "";
    }

    return (
      <ReactStrapTooltip
        trigger="click hover focus"
        innerClassName="tooltip-inner"
        {...this.props}
      />
    );
  }
}

export default Tooltip;
