import React from 'react';
import ImageFallback from '../common/ImageFallback';

function TeaserImage({ children, src, onClick }) {
  return (
    <ImageFallback image={src}>
      {image => (
        <button
          className="ClientOffer__img"
          onClick={onClick}
          type="button"
          style={{ backgroundImage: `url(${image})` }}
        >
          {children}
        </button>
      )}
    </ImageFallback>
  );
}

export default TeaserImage;
