import React from "react";
import * as signalR from "@microsoft/signalr";
import env from "../../env";
import SessionList from "./SessionList";
import themeApp from "../../themeApp";
import * as THEME from "../../themeDummyValues";
import Loading from "../common/Loading";
import DisplayError from "./DisplayError";
import versionInfo from "../../version.json";
import api from "../../api";

const REACT_APP_SMART_APP_URL = env("REACT_APP_SMART_APP_URL");

class SessionOverview extends React.Component {
  _isMounted = false;

  state = {
    agencyId: null,
    agentId: null,
    data: null,
    error: null,
    dashboardOpen: false,
  };

  dashboardButtonRef = React.createRef();

  async componentDidMount() {
    this._isMounted = true;
    document.addEventListener("click", this.onBackgroundClick);

    const { token, advisoryId } = this.props;
    const REACT_APP_SIGNALR_HUB_URL = env("REACT_APP_SIGNALR_HUB_URL");

    themeApp(THEME);

    let agencyId;
    let agentId;

    try {
      const agentAdvisory = await api.getAdvisory(advisoryId, token);
      agencyId = await api.getAgencyId(advisoryId, token);
      agentId = agentAdvisory.agent.id;
    } catch (e) {
      if (e.code === 400 || e.code === 404) {
        this.setState({
          error: e?.data?.message,
        });
      } else {
        this.setState({
          error: undefined,
        });
      }

      return;
    }

    this.setState({
      agencyId,
      agentId,
    });

    this.loadAgencyData(agencyId);

    this.signal = new signalR.HubConnectionBuilder()
      .withUrl(REACT_APP_SIGNALR_HUB_URL)
      .build();

    this.signal.on("ReceiveStartSession", () => {
      this.loadAgencyData(agencyId);
    });

    this.signal.on("ReceiveStopSession", () => {
      this.loadAgencyData(agencyId);
    });

    this.signal.onclose(this.connect);
    await this.connect();
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("click", this.onBackgroundClick);

    if (this.signal) {
      this.signal.stop();
      this.signal = null;
    }
  }

  connect = async () => {
    if (!this.signal) {
      return;
    }

    try {
      await this.signal.start();
    } catch (err) {
      console.log(err);
      setTimeout(this.connect, 5000);
      return;
    }

    const { agencyId } = this.state;

    this.signal.invoke(
      "RegisterConnectionAsync",
      JSON.stringify({
        Role: "Host",
        AgencyId: agencyId,
      })
    );
  };

  loadAgencyData = async (agencyId) => {
    const { token } = this.props;

    if (!this._isMounted) return;

    try {
      const agencyData = await api.getAgencyData(agencyId, token);

      this.setState({
        data: agencyData,
      });
    } catch (e) {
      if (e.code === 400 || e.code === 404) {
        this.setState({
          error: e?.data?.message,
        });
      } else {
        this.setState({
          error: undefined,
        });
      }
    }
  };

  onTerminateSessionClick = async (key) => {
    const { token } = this.props;

    await api.stopSession(key, token);
  };

  renderSessionMetric = (sessions) => {
    const inUseCount = sessions.filter((session) => session.isRunning).length;
    const unusedCount = sessions.length - inUseCount;
    const pluralize = (count) => (count === 1 ? "Kanal" : "Kanäle");

    return (
      <p className="mb-0">
        {unusedCount} {pluralize(unusedCount)} frei / {inUseCount}{" "}
        {pluralize(inUseCount)} belegt
      </p>
    );
  };

  onBackgroundClick = (event) => {
    if (this.dashboardButtonRef.current?.contains(event.target)) {
      return;
    }

    this.setState({
      dashboardOpen: false,
    });
  };

  toggleDashboardDropdown = () => {
    const { dashboardOpen } = this.state;

    this.setState({
      dashboardOpen: !dashboardOpen,
    });
  };

  render() {
    const { onSessionClick, advisoryId } = this.props;
    const { data, error, dashboardOpen } = this.state;

    if (error !== null) {
      return <DisplayError>{error}</DisplayError>;
    }

    if (!data) {
      return (
        <main className="ClientWidget">
          <div className="container-fluid">
            <div className="mb-3">
              <Loading />
            </div>
          </div>
        </main>
      );
    }

    return (
      <main className="ClientWidget">
        <div className="container-fluid">
          <div className="ClientWidget__advisory-number">
            <button
              className="dropdown-button"
              ref={this.dashboardButtonRef}
              onClick={this.toggleDashboardDropdown}
            >
              Beratung: <span className="font-weight-bold">{advisoryId}</span>
              {dashboardOpen && (
                <div className="dropdown-action-container">
                  <div
                    className="dropdown-action"
                    onClick={() =>
                      window.open(
                        `${REACT_APP_SMART_APP_URL}/overview/${data.agencyOverviewId}?preview=true`,
                        "_blank"
                      )
                    }
                  >
                    Zur Kanalübersicht wechseln
                  </div>
                  <div
                    className="dropdown-action"
                    onClick={() => {
                      const input = document.createElement("input");
                      input.value = `${REACT_APP_SMART_APP_URL}/overview/${data.agencyOverviewId}`;

                      document.body.appendChild(input);
                      input.select();
                      document.execCommand("copy");
                      document.body.removeChild(input);

                      input.remove();
                    }}
                  >
                    Link zur Kanalübersicht kopieren
                  </div>
                </div>
              )}
            </button>
          </div>

          <div>
            <h1 className="mb-0 h4">Meine Kanäle</h1>
            {this.renderSessionMetric(data.channels)}
          </div>
          <SessionList
            onSessionClick={onSessionClick}
            sessions={data.channels}
            onTerminateSessionClick={this.onTerminateSessionClick}
          />
        </div>
        <div className="ClientWidget__version">{versionInfo.version}</div>
      </main>
    );
  }
}

export default SessionOverview;
