import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as signalR from "@microsoft/signalr";
import api from "../../api";
import env from "../../env";
import WelcomeScreensWrapper from "./WelcomeScreensWrapper";

const REACT_APP_SIGNALR_HUB_URL = env("REACT_APP_SIGNALR_HUB_URL");

const useAgencyOverview = (agencyOverviewId, pos) => {
  const [isLoading, setIsLoading] = useState(true);
  const [refetchReq, setRefetchReq] = useState(false);

  const [overviewData, setOverviewData] = useState();
  const [requestedChannel, setRequestedChannel] = useState();

  useEffect(() => {
    (async () => {
      const data = await api.getOverviewData(agencyOverviewId).catch(() => {
        setIsLoading(false);
      });

      // eslint-disable-next-line eqeqeq
      const reqChannel = data?.sessions?.find((session) => session.pos == pos);

      setOverviewData(data);
      setRequestedChannel(reqChannel);

      setRefetchReq(false);
      setIsLoading(false);
    })();
  }, [agencyOverviewId, pos, refetchReq]);

  return {
    isLoading,
    overviewData,
    requestedChannel,
    fetchData: setRefetchReq,
  };
};

const useSignalR = (overviewId, onReload) => {
  const [signalr, setSignalR] = useState();

  useEffect(() => {
    (async () => {
      const signalrConn = new signalR.HubConnectionBuilder()
        .withUrl(REACT_APP_SIGNALR_HUB_URL)
        .build();

      signalrConn.onclose(async () => {
        await signalrConn.start();

        try {
          await signalrConn.invoke(
            "RegisterConnectionAsync",
            JSON.stringify({
              Role: "Guest",
              AgencyOverviewId: overviewId,
            })
          );
        } catch (err) {
          console.log(err);
        }
      });

      signalrConn.on("ReceiveError", (message) => {
        const error = JSON.parse(message);
        console.log(error);
      });

      signalrConn.on("ReceiveStartSession", (_) => onReload());

      signalrConn.on("ReceiveStopSession", (_) => onReload());

      await signalrConn.start();

      await signalrConn.invoke(
        "RegisterConnectionAsync",
        JSON.stringify({
          Role: "Guest",
          AgencyOverviewId: overviewId,
        })
      );

      setSignalR(signalrConn);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  return { signalr };
};

const useInitOverview = (overviewId, pos, onReload) => {
  const { isLoading, overviewData, requestedChannel, fetchData } =
    useAgencyOverview(overviewId, pos);
  const { signalr } = useSignalR(overviewId, onReload);

  return { isLoading, overviewData, requestedChannel, fetchData, signalr };
};

const AgencyOverviewChannel = ({ match }) => {
  const reload = () => fetchData(true);

  const { agencyOverviewId, pos } = match.params;
  const { isLoading, overviewData, requestedChannel, fetchData } =
    useInitOverview(agencyOverviewId, pos, reload);

  useEffect(() => {
    let manifestDom = document.querySelector("#manifest");

    if (!manifestDom) {
      document.head.insertAdjacentHTML(
        "beforeend",
        '<link id="manifest" rel="manifest" />'
      );

      manifestDom = document.querySelector("#manifest");

      if (!manifestDom) {
        return;
      }
    }

    const manifest = {
      "short_name": "Smartberatung",
      "name": "Smartberatung",
      "icons": [
        {
          "src": "/favicon.ico",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/x-icon"
        }
      ],
      "start_url": `/overview/${agencyOverviewId}/${pos}`,
      "display": "standalone",
      "theme_color": "#000000",
      "background_color": "#ffffff"
    };

    const stringManifest = JSON.stringify(manifest);
    const blobManifest = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blobManifest);

    manifestDom.setAttribute("href", manifestURL);
  }, [agencyOverviewId, pos]);

  if (isLoading) {
    return <WelcomeScreensWrapper>{"Bitte warten ..."}</WelcomeScreensWrapper>;
  }

  if (!overviewData) {
    return <WelcomeScreensWrapper>{"Nicht gefunden!"}</WelcomeScreensWrapper>;
  }

  if (!requestedChannel) {
    return (
      <WelcomeScreensWrapper>{"Kanal nicht gefunden"}</WelcomeScreensWrapper>
    );
  }

  if (!requestedChannel.isRunning) {
    return (
      <>
        <WelcomeScreensWrapper>
          <div style={{ fontSize: "1.5em" }}>
            <div>Bitte haben sie noch etwas Geduld.</div>
            <div style={{ fontSize: "1.5em" }}>
              Ihre Beratung startet gleich ...
            </div>
          </div>
        </WelcomeScreensWrapper>
      </>
    );
  }

  return (
    <WelcomeScreensWrapper>
      <Redirect
        to={{
          pathname: `/${requestedChannel.id}`,
          state: { agencyOverviewId, pos },
        }}
      />
    </WelcomeScreensWrapper>
  );
};

export default AgencyOverviewChannel;
