import React from "react";
import { Modal } from "reactstrap";
import api from "../../api";

const STATUS_LOADING = "loading";
const STATUS_LOADED = "loaded";
const STATUS_ERROR = "error";

export default class ContentModal extends React.Component {
  state = {
    data: "",
    modalOpen: false,
    status: STATUS_LOADING,
  };

  toggleModal = () => {
    const { modalOpen, status } = this.state;

    if (
      (status === STATUS_LOADING || status === STATUS_ERROR) &&
      modalOpen === false
    ) {
      this.load();
    }

    if (status === STATUS_LOADED && modalOpen === true) {
      this.setState({
        status: STATUS_LOADING,
      });
    }

    this.setState({
      modalOpen: !modalOpen,
      data: this.data,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  async load() {
    const { url } = this.props;
    const response = await api.getXApiContent(url);
    const data = await response.text();

    this.setState({
      data: data,
      status: STATUS_LOADED,
    });
  }

  render() {
    const { data, modalOpen, status } = this.state;
    const { btnText } = this.props;

    let content =
      "Die Daten konnten nicht geladen werden. Bitte versuche es später nochmal.";

    if (status === STATUS_LOADING) {
      content = "Bitte warten, Daten werden geladen";
    }

    if (status === STATUS_LOADED) {
      content = data;
    }

    return (
      <div className="ModalContent__wrapper">
        <button
          type="button"
          className="btn-unstyled text-white"
          onClick={this.toggleModal}
        >
          {btnText}
        </button>
        <Modal
          isOpen={modalOpen}
          toggle={this.toggleModal}
          className="ModalContent"
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Modal>
      </div>
    );
  }
}
