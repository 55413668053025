import React from "react";
import * as helper from "../../helper";

function Insurance({ insurances }) {
  return (
    <section id="insurance" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Reiseschutz</h1>
      </header>
      {insurances.map((insurance) => (
        <div className="OfferDetail__sub-section" key={insurance.id}>
          <div className="mb-4">
            {insurance.providerImage && (
              <img
                className="d-block mb-3 additionals-img"
                src={insurance.providerImage}
                alt=""
              />
            )}
            <div className="row pl-2 mb-2">
              <h3 className="h4">{insurance.name}</h3>
            </div>
            <div className="row pl-2 w-100 justify-content-between">
              <div className="align-self-end">
                <div
                  dangerouslySetInnerHTML={{
                    __html: helper.decodeHTMLEntities(insurance.description),
                  }}
                />
                <br />
                <h5 className="mb-0">
                  {helper.convertISOCurrencyToSymbol(insurance.price)}
                </h5>
                <p>Gesamtpreis {insurance.duration}</p>
              </div>
              {insurance.providerName && (
                <>
                  <div className="align-self-end">
                    <h6>Ihr Anbieter:</h6>
                    {insurance.providerName}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Insurance;
