import React from "react";
import moment from "moment";

import ContentModalIFrame from "./ContentModalIFrame";

const map = {
  hotel: "Hotel Allgemein",
  room: "Zimmer",
  service: "Service",
  location: "Lage",
  food: "Gastronomie",
  sport: "Sport & Unterhaltung",
};

const holidayCheckLogo =
  "https://cdn.smartberatung.com/logos/addoncontent/holidaycheck.png";

const RatingHolidayCheck = ({ hotelV3, hotel, rating }) => {
  const v3Data = JSON.parse(hotelV3);

  if (!v3Data) {
    return (
      <div className="OfferDetail__sub-section">
        <div className="row">
          <div className="col-4 mb-3">
            <img
              className="RatingHolidayCheck__logo"
              src={holidayCheckLogo}
              alt=""
            />
          </div>
        </div>
        <div className="RatingError">Es wurden keine Bewertungen gefunden.</div>
      </div>
    );
  }

  return (
    <div className="OfferDetail__sub-section">
      <div className="row">
        <div className="col-4 mb-3">
          <img
            className="RatingHolidayCheck__logo"
            src={holidayCheckLogo}
            alt=""
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="RatingHolidayCheck__overall-wrapper">
            {v3Data.reviewCalculations.overall.recommendation} %<br />
            Weiterempfehlung
          </div>
        </div>
        <div className="col-6">
          <div className="RatingHolidayCheck__overall-wrapper">
            {v3Data.reviewCalculations.overall.rating} von 6<br />
            Gesamtbewertung
          </div>
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col-6">
          <p className="mb-0">
            {v3Data.reviewCalculations.overall.countUnarchived} Bewertungen
          </p>
        </div>
      </div>
      {Object.entries(map).map(([key, label]) => {
        if (!v3Data.reviewCalculations.ratingGroups[key]) {
          return null;
        }

        const value = v3Data.reviewCalculations.ratingGroups[key];

        return (
          <div key={key} className="row align-items-center mb-2">
            <div className="col-7">{label}</div>
            <div className="col-5 d-flex justify-content-end">
              <div className="RatingHolidayCheck__sunny-box">
                <img
                  className="RatingHolidayCheck__sunny-icon"
                  src="https://cdn.smartberatung.com/icons/holidaycheck/sun-holidaycheck.svg"
                  alt="HolidayCheckSun"
                />
                <div className="RatingHolidayCheck__sunny-value">
                  <b>{value.toFixed(1)}</b>
                  /6
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {v3Data.widgetUrl && (
        <div>
          <ContentModalIFrame
            url={v3Data.widgetUrl}
            btnText="BEWERTUNGSDETAILS ANZEIGEN"
            className="ModalContent ModalContent__HolidayCheck"
            title="Holidaycheck Iframe"
          />
        </div>
      )}
      <p>&copy; {moment().format("Y")} HolidayCheck</p>
    </div>
  );
};

export default RatingHolidayCheck;
