import React from "react";
import TeaserImage from "./TeaserImage";

function Vehicle({ offer }) {
  const teaserImage =
    offer.rentalService.images[0] && offer.rentalService.images[0].src;

  return (
    <section id="features-vehicle" className="OfferDetail__section">
      <header className="OfferDetail__section-header">
        <h1 className="OfferDetail__section-headline h1">Fahrzeug</h1>
      </header>
      <div className="row">
        <div className="col-6 mb-3">
          <TeaserImage
            key={teaserImage}
            src={teaserImage}
            additionalClass="vehicle_image"
          />
        </div>
        <div className="col-6 vehicle__feature_table">
          <b>Eigenschaften</b>
          <ul>
            <li>Fahrzeugname {offer.rentalService.vehicle.name}</li>
            <li>Fahrzeugart {offer.rentalService.vehicle.type}</li>
            <li>
              Anzahl Passagiere {offer.rentalService.vehicle.passengerCount}
            </li>
            <li>Anzahl Türen {offer.rentalService.vehicle.doorCount}</li>
            {offer.rentalService.vehicle.features &&
              offer.rentalService.vehicle.features.map(
                (vehicleFeature, index) => (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: vehicleFeature }}
                  />
                )
              )}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Vehicle;
