import React from "react";
import semver from "semver";
import env from "../../env";
import { ReactComponent as Globe } from "../../assets/img/globe-americas.svg";
import { ReactComponent as Close } from "../../assets/img/times-light.svg";
import { ReactComponent as Video } from "../../assets/img/phone-solid.svg";
import { ReactComponent as VideoClose } from "../../assets/img/phone-slash-solid.svg";
import { ReactComponent as Euro } from "../../assets/img/euro-sign-solid.svg";
import { ReactComponent as Slash } from "../../assets/img/slash-solid.svg";
import { ReactComponent as Mail } from "../../assets/img/mail.svg";

function SessionHeaderButtons({
  selectedSessionKey,
  onVideo,
  inCall,
  togglePrices,
  displayPrices,
  openModal,
  paxVersion,
  matomoFunc,
}) {
  const REACT_APP_SMART_APP_URL = env("REACT_APP_SMART_APP_URL");
  const mailUrl = `paxlounge://v1/sendlinkmail?link=${REACT_APP_SMART_APP_URL}/${selectedSessionKey}`;

  return (
    <div className="d-flex align-items-center">
      {semver.gte(paxVersion, "2.18.0") && (
        <VideoButton onVideo={onVideo} inCall={inCall} />
      )}

      <CircleButton
        onClick={togglePrices}
        title="Preise Ein-/Ausblenden"
        className={!displayPrices ? "CircleButton--lighten" : ""}
      >
        {!displayPrices && <Slash height={18} width={18} />}
        <Euro height={18} width={18} />
      </CircleButton>
      <CircleButton
        onClick={() => {
          matomoFunc("SmartAppOpen");
          window.open(
            `${REACT_APP_SMART_APP_URL}/${selectedSessionKey}?ref=preview`,
            "_blank"
          );
        }}
        title="Smart App öffnen"
      >
        <Globe height={16} width={16} />
      </CircleButton>
      <CircleButton
        onClick={() => {
          matomoFunc("MailSend");
          window.open(mailUrl, "_blank");
        }}
        title="Versenden"
      >
        <Mail height={16} width={16} />
      </CircleButton>
      <CircleButton onClick={openModal} title="smart-Beratung beenden">
        <Close height={18} width={18} />
      </CircleButton>
    </div>
  );
}

const VideoButton = ({ onVideo, inCall }) => (
  <CircleButton onClick={onVideo} title="Video Starten">
    {inCall ? (
      <VideoClose height={16} width={16} />
    ) : (
      <Video height={16} width={16} />
    )}
  </CircleButton>
);

const CircleButton = ({ children, className = "", ...props }) => (
  <button type="button" className={`CircleButton ${className}`} {...props}>
    {children}
  </button>
);

export default SessionHeaderButtons;
