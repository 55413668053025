import React from "react";
import { withRouter } from "react-router-dom";

class Login extends React.PureComponent {
  state = {
    sessionKey: "",
  };

  onChange = (event) => {
    let value = event.target.value
      .trim()
      .replaceAll("-", "")
      .substr(0, 6)
      .toUpperCase();

    if (value.length === 3 && event.nativeEvent.inputType === "insertText") {
      value = value + "-";
    } else if (value.length >= 4) {
      const part1 = value.substr(0, 3);
      const part2 = value.substr(3, 3);

      value = `${part1}-${part2}`;
    }

    this.setState({
      sessionKey: value,
    });
  };

  render() {
    const { sessionKey } = this.state;
    const { history } = this.props;

    return (
      <form
        className="mt-3"
        onSubmit={(event) => {
          event.preventDefault();

          history.push(`/${sessionKey}`);
        }}
      >
        <div className="input-group">
          <input
            readOnly={false}
            disabled={false}
            className="form-control"
            value={sessionKey}
            onChange={this.onChange}
            type="text"
            placeholder="smart-Key eingeben"
            autoFocus
          />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="submit">
              Öffnen
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(Login);
