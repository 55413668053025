import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ClientWidget from "./page/ClientWidget";
import SmartApp from "./page/SmartApp";
import "./App.scss";
import history from "./history";
import withAppInsights from "./AppInsights";

const isApp = process.env.REACT_APP_ENTRY === "app";

function App() {
  if (isApp === true) {
    return (
      <Router history={history}>
        <SmartApp />
      </Router>
    );
  }

  return <ClientWidget />;
}

export default withAppInsights(App);
