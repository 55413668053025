import React from "react";
import * as helper from "../../helper";

function RentalCar({ rentalCars }) {
  return (
    <>
      {rentalCars.map((rentalCar) => (
        <div key={rentalCar.id} className="OfferDetail__sub-section">
          <div className="mb-4">
            <img
              className="d-block mb-3 additionals-img"
              src={rentalCar.image}
              alt=""
            />
            <div className="row pl-2 mb-2">
              <h3 className="h4">{rentalCar.name}</h3>
            </div>
            <div className="row pl-2 mb-2">
              <div
                dangerouslySetInnerHTML={{ __html: rentalCar.description }}
              />
            </div>
            <div className="row pl-2 w-100 justify-content-between">
              <div className="align-self-end">
                <h5 className="mb-0">
                  {helper.convertISOCurrencyToSymbol(rentalCar.price)}
                </h5>
                <p>
                  {rentalCars.priceLabel ?? "Gesamtpreis"}{" "}
                  {rentalCars.showDuration && rentalCars.duration}
                </p>
              </div>
              <img
                className="additionals-logo"
                src={rentalCar.providerImage}
                alt={rentalCar.providerName}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default RentalCar;
