import React from 'react';
import { Modal } from 'reactstrap';
import Slider from 'react-slick';
import ImageFallback from '../common/ImageFallback';

/* eslint react/no-unescaped-entities: "off" */

/* eslint object-curly-newline: "off" */

class Gallery extends React.PureComponent {
  slider = React.createRef();

  componentDidMount() {
    const { onGalleryOpened } = this.props;

    onGalleryOpened();
  }

  componentDidUpdate(prevProps) {
    const { index } = this.props;

    if (!this.slider.current) {
      return;
    }

    if (prevProps.index !== index) {
      this.slider.current.slickGoTo(index);
    }
  }

  componentWillUnmount() {
    const { onGalleryClosed } = this.props;

    onGalleryClosed();
  }

  render() {
    const {
      images,
      index,
      onNavigateGallery,
      onGalleryClose,
    } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 3000,
      initialSlide: index || 0,
      afterChange: onNavigateGallery,
    };

    return (
      <Modal
        isOpen
        toggle={onGalleryClose}
        className="ModalSlider"
      >
        {/* eslint-disable-next-line no-return-assign */}
        <Slider ref={this.slider} {...settings}>
          {images.map(image => (
            <ImageFallback key={image.src} image={image.src}>
              {src => (
                <div className="ModalSlider__item">
                  <div
                    className="ModalSlider__image"
                    style={{ backgroundImage: `url(${src})` }}
                  />
                </div>
              )}
            </ImageFallback>
          ))}
        </Slider>
      </Modal>
    );
  }
}

export default Gallery;
